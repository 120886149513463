import React from 'react';
import {
    BrowserRouter as Router, Redirect, Route, Switch
} from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import ForgotPassword from './login/authUser/Forgot-Password';
import SignUp from './login/authUser/SignUp';
import Login from './login/Login';
import CompanyPortfolio from './portal/company-portfolio/Company-Portfolio';
import CompanyProfile from './portal/company-Profile/Company-profile';
import SearchDashboard from './portal/company-search/Search';

interface AuthState {
    isSessionValid: boolean | null;
    routepage: 'login' | 'company-search' | null;
}
export default class AuthRoute extends React.Component<{ session: boolean }, AuthState> {
    constructor(props: any) {
        super(props);
        this.state = ({
            isSessionValid: null,
            // history: this.props.history
            routepage: null
        })
    }

    componentDidMount() {
        this.authSession();
    }

    authSession = () => {
        console.log(this.state.isSessionValid)
        if (reactLocalStorage.getObject('authState') && reactLocalStorage.getObject('authState').isLoggedIn == true) {
            console.log('navigate to company search');
            return <Redirect to='/company-search' />
        }
        else {
            console.log('navigate to login');

            return <Redirect to='/login' />
        }
    }

    render() {
        return (
            <React.Fragment>
                <Router >
                    <Switch>
                        <Route path="/" exact>
                            {
                                this.authSession()
                            }

                        </Route>
                        <Route path="/login" render={(props) =>

                            <Login {...props} auth={this.state.isSessionValid} />} />
                        <Route path="/company-search" render={(props) =>
                            <SearchDashboard {...props} auth={this.props.session} />}

                        />
                        <Route path="/forgot-password" exact render={(props) => <ForgotPassword {...props} auth={this.props.session} />} />
                        <Route path="/reset-password" exact render={(props) => <ForgotPassword {...props} auth={this.props.session} />} />
                        <Route path="/sign-up" exact render={(props) => <SignUp {...props} />} />


                        <Route path="/company-profile/:name/companyurl=:url/criteria=:criteria" render={(props) => <CompanyProfile {...props} auth={this.props.session} />} />
                        <Route path="/company-profile/companyurl=:url/criteria=:criteria" render={(props) => <CompanyProfile {...props} auth={this.props.session} />} />
                        <Route path="/company-profile/:name/companyurl=:url" render={(props) => <CompanyProfile {...props} auth={this.props.session} />} />
                        <Route path="/company-profile/companyurl=:url" render={(props) => <CompanyProfile {...props} auth={this.props.session} />} />
                        <Route path="/company-portfolio" exact render={(props) => <CompanyPortfolio {...props} auth={this.props.session} />} />
                        <Route path="*" exact render={(props) => <Login {...props} auth={this.props.session} />} />

                    </Switch>
                </Router>
            </React.Fragment >
        )
    }
}


