import axios from "axios";
import { reactLocalStorage } from 'reactjs-localstorage';
import { authSession } from "../components/auth";
import { AuthorizationToken } from "../components/shared/interface";
import * as api from './shared-axios';

export const apiGetFundExclusionApiUrl = () => { // FundExclusion get api call
  const Headers: AuthorizationToken = { Authorization: reactLocalStorage.getObject('jwtToken').Authorization };
  return axios.get(`${api.BaseUrl+api.FundExclusion}`, {
    headers: Headers
  });
}

export const apiGetCompaniesHouseApiUrl = (companyName: string) => { // Company house data get api call by company name
  const Headers: AuthorizationToken = { Authorization: reactLocalStorage.getObject('jwtToken').Authorization };
  return axios.get(`${api.BaseUrl+api.CompaniesHouse}/?name=${companyName}`, {
    headers: Headers
  });
}

export const apiGetWikipediaByCompanyName = (companyName: string) => { // wikipedia get api call by company name 
  const Headers: AuthorizationToken = { Authorization: reactLocalStorage.getObject('jwtToken').Authorization };
  return axios.get(`${api.BaseUrl+api.Wikipedia}?name=${companyName}`, {
    headers: Headers
  })
}

export const getTempPortfolioData = async () => { // function call with static portfolio data
  return new Promise(async (resolve, reject) => { //TO DO : Portfolio api integration
    const authorization = await authSession();
    if (authorization == true)
      resolve(api.PortFolioData);
    else reject({ error: { status: 401, statusText: 'Unauthorized' } })
  })
}

export const getTempSectorsData = async () => {  // function call with static portfolio data
  return new Promise(async (resolve, reject) => { //TO DO : Portfolio api integration
    const authorization = await authSession();
    if (authorization == true)
      resolve(api.PortfolioSectors);
    else reject({ error: { status: 401, statusText: 'Unauthorized' } })
  })
}

export const apiPostStartFunction = (queryStringParameters: { url: string, name: string }) => { // start function post api call to get exclusionArn by company name
  const requestbody = { queryStringParameters }
  const Headers: AuthorizationToken = { Authorization: reactLocalStorage.getObject('jwtToken').Authorization };
  return axios.post(`${api.BaseUrl+api.StartFunction}`, requestbody, {
    headers: Headers
  });
}

export const apiPostPollFunction = (executionArn: string) => { // poll function post api call to get exclusionArn by exclusionArn from start api function
  const requestbody = {
    executionArn: executionArn
  }
  const Headers = {
    Authorization: reactLocalStorage.getObject('jwtToken').Authorization,
  };
  return axios.post(`${api.BaseUrl+api.PollFunction}`, requestbody, {
    headers: Headers
  });
}
