import React from 'react';

export const KeyMetrics: React.FC = () => {
    return (
        <div className="key_meteics_outer">
            <div className="actual_exclusions_side_title">
                <div className="row">
                    <div className="col-md-12">
                        <h1>
                            <i className="fas fa-arrow-right"></i>Key Metrics
									</h1>
                    </div>
                </div>
            </div>
            <div className="key_meteics box_content_outer company-profile-keyMetrics">
                <ul>
                    <li>
                        <h1>21<span>Weapons</span></h1>
                        <p>The number of exclusion lists the company already appears on</p>
                    </li>

                    <li>
                        <h1>34<span>Cluster</span></h1>
                        <p>The number of funds we know of that would likely exclude such a company</p>
                    </li>

                    <li>
                        <h1>04<span>Munitions</span></h1>
                        <p>Number of sector categories outside the company’s reported NAICS or SIC code
										(higher numbers indicate increased risk of miscategorisation) </p>
                    </li>
                </ul>
            </div>
        </div>
    )
}