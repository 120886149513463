import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FilterTable } from './screens/Filter-Table';
import { Navbar } from '../common/Navbar';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Auth } from 'aws-amplify';
import * as fetchData from '../../../utils/API';
import { growlBehaviorSubject, ExclusionRiskFilter } from '../../shared/interface';
import { SessionTimeOut } from '../../shared/constant';
import * as _ from 'lodash';
import { CompanyInfo } from './screens/company-info';
import { KeyMetrics } from './screens/key-metrics';

type Color = 'red' | 'yellow' | 'green';
type ExclusionRisk = 'High' | 'Medium' | 'Low';

interface Exclusionrisk {
    level: ExclusionRisk,
    criteria: string
}
interface TableData {
    id: number,
    company: string,
    sectors: string[],
    sector_mismatch: number,
    exclusion_risk: Exclusionrisk[],
    known_exclusions: number
}
const risk: ExclusionRiskFilter = {
    high: false,
    medium: false,
    low: false
}
class CompanyPortfolio extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = ({
            portfolioData: [],
            filteredPortfoliodata: [],
            searchCompanyKey: '',
            sectorList: [],
            searchSectorKey: '',
            exclusionHighRiskFilter: false,
            exclusionMediumRiskFilter: false,
            exclusionLowRiskFilter: false,
            sectorMisMatchCheck: false,
            knownExclusionsCheck: false,
            misCategoriseCheck: false

        })
    }

    componentDidMount() {
        if (reactLocalStorage.getObject('authState') && reactLocalStorage.getObject('authState').isLoggedIn == true) {
        } else {
            this.props.history.push("/login");
        }
        this.fecthPortfolioData();
        // TO DO :this will be replaced with API Integration
    }



    fecthPortfolioData = async () => {
        fetchData.getTempSectorsData().then((data) => {
            if (data) {
                this.setState({
                    sectorList: data
                })
            }
        }).catch(error => {
            if (error.error.status == 401 && error.error.statusText == "Unauthorized") {
                this.onSignOut();
                growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: SessionTimeOut, life: 6000 })
            }
        })
        fetchData.getTempPortfolioData().then(data => {
            if (data) {
                this.setState({
                    portfolioData: data,
                    filteredPortfoliodata: data
                })
            }
        }).catch(error => {
            if (error.error.status == 401 && error.error.statusText == "Unauthorized") {
                this.onSignOut();
                growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: SessionTimeOut, life: 6000 })
            }
        })
    }

    exclusionrisk: { key: string, placement: string, color: Color }[] = [
        { key: 'High', placement: 'bottom-start', color: 'red' },
        { key: 'Medium', placement: 'bottom-start', color: 'yellow' },
        { key: 'Low', placement: 'bottom-start', color: 'green' }
    ]

    backToSearch = () => {
        this.props.history.push('/company-search');
    }

    onSelectSector = (event: React.ChangeEvent<any>) => {
        if (event.target.value) {
            this.setState({
                searchSectorKey: event.target.value
            })
        } else {
            this.setState({
                searchSectorKey: ''
            })
        }
    }

    onCompanySearch = (event: React.ChangeEvent<any>) => {
        if (event.target.value) {
            this.setState({
                companySearchkey: event.target.value,
            })
        } else {
            this.setState({
                companySearchkey: '',
            })
        }
    }

    onFilter = () => {
        let filteredData: TableData[] = this.state.portfolioData;
        if (this.state.companySearchkey) {
            filteredData = filteredData.filter((item: TableData) =>
                (item.company.toLocaleLowerCase().includes(this.state.companySearchkey.toLocaleLowerCase())))
        }
        if (this.state.searchSectorKey) {
            filteredData = filteredData.filter((item: TableData) =>
                item.sectors.includes(this.state.searchSectorKey))
        }
        if (this.state.sectorMisMatchCheck) {
            filteredData = filteredData.filter((item: TableData) =>
                item.sector_mismatch > 0)
        }
        if (this.state.knownExclusionsCheck) {
            filteredData = filteredData.filter((item: TableData) =>
                item.known_exclusions > 0)
        }
        else {
            // filteredData = this.state.portfolioData;
        }
        this.setState({
            filteredPortfoliodata: filteredData
        })
    }

    OverlayTrigger = () => {
        this.exclusionrisk.map((data: { key: string, placement: string, color: Color }, index) => {
            return <OverlayTrigger
                key={data.key}
                placement={'bottom-start'}
                overlay={
                    <Tooltip id={data.key}>
                        <strong>{data.key}</strong>
                    </Tooltip>
                }
            >
                <span className={data.color}></span>
            </OverlayTrigger>
        })
    }

    onSignOut = async () => {
        this.props.history.push('/login');
        reactLocalStorage.clear();
        Auth.signOut();

        reactLocalStorage.setObject('authState', {
            isLoggedIn: false
        })
    }


    // onCheckExclusion(inputCheck: ExclusionRisk) {
    //     let { exclusionHighRiskFilter } = this.state.exclusionHighRiskFilter;
    //     console.log('exclusionRiskFilter==>', exclusionHighRiskFilter)
    //     if (inputCheck) {
    //         switch (inputCheck) {
    //             case 'High':
    //                 this.setState((prevState: any) => ({
    //                     exclusionHighRiskFilter: prevState.exclusionHighRiskFilter == true ? false : true
    //                 }))
    //                 break;
    //             case 'Medium': this.setState((prevState: any) => ({
    //                 exclusionHighRiskFilter: !prevState.exclusionMediumRiskFilter == true
    //             }))
    //                 break;
    //             case 'Low':
    //                 this.setState((prevState: any) => ({
    //                     exclusionHighRiskFilter: !prevState.exclusionLowRiskFilter == true
    //                 }))
    //                 break;
    //             default:
    //                 this.setState((prevState: any) => ({
    //                     exclusionHighRiskFilter: !prevState.exclusionHighRiskFilter,
    //                     exclusionMediumRiskFilter: !prevState.exclusionMediumRiskFilter,
    //                     exclusionLowRiskFilter: !prevState.exclusionLowRiskFilter
    //                 }))
    //         }
    //     }
    // }

    onToggleSectorMisMatchCheck = () => {
        let { sectorMisMatchCheck } = this.state;

        this.setState({
            sectorMisMatchCheck: !sectorMisMatchCheck
        })
    }

    onToggleKnownExclusionCheck = () => {
        let { knownExclusionsCheck } = this.state
        this.setState({
            knownExclusionsCheck: !knownExclusionsCheck
        })
    }

    onToggleMiscategoriseCheck = () => {
        let { misCategoriseCheck } = this.state
        this.setState({
            misCategoriseCheck: !misCategoriseCheck
        })
    }

    render() {
        return (
            <React.Fragment >
                <FilterTable
                    companySearchkey={this.state.companySearchkey}
                    sectorList={this.state.sectorList}
                    sectorMisMatchCheck={this.state.sectorMisMatchCheck}
                    // exclusionLowRiskFilter={this.state.exclusionLowRiskFilter}
                    // exclusionMediumRiskFilter={this.state.exclusionMediumRiskFilter}
                    // exclusionHighRiskFilter={this.state.exclusionHighRiskFilter}
                    misCategoriseCheck={this.state.misCategoriseCheck}
                    knownExclusions={this.state.knownExclusions}
                    onCompanySearch={this.onCompanySearch}
                    onSelectSector={this.onSelectSector}
                    onToggleSectorMisMatchCheck={this.onToggleSectorMisMatchCheck}
                    // onCheckExclusion={this.onCheckExclusion}
                    onToggleMiscategoriseCheck={this.onToggleMiscategoriseCheck}
                    onToggleKnownExclusionCheck={this.onToggleKnownExclusionCheck}
                    onFilter={this.onFilter}
                />

                <Navbar onSignOut={this.onSignOut} />
                <div id="wrapper">
                    <div className="main-content">
                        <div className="company_profile_outer">
                            <span className="search_back"
                                onClick={this.backToSearch}
                            >
                                <i className="fas fa-arrow-left"></i>Back to Search</span>
                            <CompanyInfo />


                            <div id="section-1" className="dcm_key_meteics_outer">
                                <KeyMetrics />
                                <div id="section-2" className="fund_exclusions_outer">
                                    <div className="actual_exclusions_side_title">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h1>
                                                        <i className="fas fa-arrow-right"></i>Portfolio
										</h1>
                                                    <div className="grid-pagination">
                                                        <span>{this.state.filteredPortfoliodata ? this.state.filteredPortfoliodata.length : 0}</span>
                                                        <ul className="pagination my-0 mx-4">
                                                            <li className="disabled">
                                                                <a aria-label="Previous">
                                                                    <span aria-hidden="true"><i className="fas fa-angle-left"></i></span>
                                                                </a>
                                                            </li>
                                                            <li className="active"><a >1</a></li>
                                                            <li>
                                                                <a aria-label="Next">
                                                                    <span aria-hidden="true"><i className="fas fa-angle-right"></i></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        {/* <span>of 900 pages</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="company_profile_table_outer box_content_outer">
                                        <div className="table-responsive">
                                            <table className="table tbl-portfolio">
                                                <thead>
                                                    <tr>
                                                        <th>Company</th>
                                                        <th>Sectors</th>
                                                        <th className="text-center">Sector Mismatch</th>
                                                        <th style={{ width: "30%" }} >Exclusion Risk</th>
                                                        <th className="text-center">known Excl</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.filteredPortfoliodata.length > 0 ? this.state.filteredPortfoliodata.map((value: TableData, index: number) => {
                                                        return <tr key={value.id}>
                                                            <td className="font-weight-bold">{value.company}</td>
                                                            <td>{value.sectors.join(', ')}</td>
                                                            <td className="text-center"><span className="grid-status danger">{value.sector_mismatch}</span></td>
                                                            <td className="vertical-middle">
                                                                <div className="exclusion-risk">
                                                                    <div className="color-bar mr-3">
                                                                        {
                                                                            this.exclusionrisk.map((data, i) => {
                                                                                return <OverlayTrigger
                                                                                    key={i}
                                                                                    placement={'bottom-start'}
                                                                                    overlay={
                                                                                        <Tooltip id={data.key}>
                                                                                            <strong>{data.key}</strong>
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className={data.color}></span>
                                                                                </OverlayTrigger>
                                                                            })
                                                                        }

                                                                    </div>
                                                                    {value.exclusion_risk.map((data: Exclusionrisk, k) => {
                                                                        return <p className="mb-0">
                                                                            {data.criteria + ', '}
                                                                        </p>
                                                                    })}

                                                                </div>
                                                            </td>
                                                            <td className="text-center"><span className="grid-status success">{value.known_exclusions}</span></td>
                                                        </tr>
                                                    }) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="grid-pagination justify-content-end">
                                        <span>{this.state.filteredPortfoliodata ? this.state.filteredPortfoliodata.length : 0}</span>
                                        <ul className="pagination my-0 mx-4">
                                            <li className="disabled">
                                                <a aria-label="Previous">
                                                    <span aria-hidden="true"><i className="fas fa-angle-left"></i></span>
                                                </a>
                                            </li>
                                            <li className="active"><a >1</a></li>
                                            <li>
                                                <a aria-label="Next">
                                                    <span aria-hidden="true"><i className="fas fa-angle-right"></i></span>
                                                </a>
                                            </li>
                                        </ul>
                                        {/* <span>of 900 pages</span> */}
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default CompanyPortfolio;