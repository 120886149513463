import React from 'react';


export const CompanyProfileKeyMetrics: React.FC = () => {
    return (
        <React.Fragment>
            <div className="key_meteics_outer  box_content_outer">
                <h2>Key Metrics</h2>
                <div className="key_meteics">
                    <ul>
                        <li>
                            <h1>21<span>Weapons</span></h1>
                            <p>Exclusion lists the company already appears on</p>

                        </li>
                        <li>
                            <h1>34<span>Cluster</span></h1>
                            <p>Funds we know of that would likely exclude this company</p>
                        </li>
                        <li>
                            <h1>22<span>Uranium</span></h1>
                            <p>Board connections the company shares with companies that would be, or already are, excluded from funds for these reasons</p>
                        </li>

                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}