import React from 'react';

export const CompanyInfo: React.FC = () => {
    return (
        <div className="row company_points">
            <div className="col-md-4">
                <div className="company_point-box">
                    <span className="point-number">05</span>
                    <div className="point-disc">
            
                        The number of exclusion lists
							</div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="company_point-box">
                    <span className="point-number">05</span>
                    <div className="point-disc">
                        The number of exclusion lists
							</div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="company_point-box">
                    <span className="point-number">05</span>
                    <div className="point-disc">
                        The number of exclusion lists
							</div>
                </div>
            </div>
        </div>
    )
}