import { Auth } from 'aws-amplify';
import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import * as fetchData from '../../../utils/API';
import { SessionTimeOut } from '../../shared/constant';
import {
    AISectorDetection,
    CompaniesHouseInterface,
    ExclusionCriteriaInterface,
    ExclusionRisk, FundExclusionInterface,
    growlBehaviorSubject, sectorDataBehaviorSubject,
    SectorDataInterface, sectorUnit, spinnerSubject, wikipedia
} from '../../shared/interface';
import { Navbar } from '../common/Navbar';
import { CompanyInfo } from './sections/CompanyProfileInfo';
import { CompanyProfileKeyMetrics } from './sections/CompanyProfileKeyMetrics';
import { FundExclusions } from './sections/Fund-Exclusions';
import { SectorInsights } from './sections/Sector-Insights';
import { SidebarFilters } from './sidebar-filters/Sidebar-Filters';

let apiCallTimeout: NodeJS.Timeout | null = null;
let apiCallTimeout1: NodeJS.Timeout | null = null;

const CompanyProfile: React.FC<any> = (props: any) => {
    const params: {
        name?: string,
        url: string,
        criteria?: string
    } = useParams();
    const [fundexclusionData, setFundexclusionData] = React.useState<FundExclusionInterface | null>(null);
    const [sectorData, setSectorData] = React.useState<SectorDataInterface | null>(null);
    const [currentPage, setCurrentPage] = React.useState<'search' | 'profile'>('profile');
    const [companyName, setCompanyName] = React.useState<string>(params?.name ? params.name : '');
    const [companyUrl, setCompanyUrl] = React.useState<string>(params?.url ? params.url : '');
    const [options, getOptions] = React.useState<ExclusionCriteriaInterface[]>(
        reactLocalStorage.getObject('Exclusion_Criteria').length > 0 ?
            reactLocalStorage.getObject('Exclusion_Criteria') : []
    );
    const [selectedOptions, selectOptions] = React.useState<ExclusionCriteriaInterface[]>(props?.selectedOptions ? props.selectedOptions : []);
    const [customUrl, setCustomUrl] = React.useState<string>('');
    const [executionArn, setExecutionArn] = React.useState<string>('');
    const [dataAISectorDetection, setDataAISectorDetection] = React.useState<AISectorDetection[]>([])
    const [exclusionRisklevel, setExclusionListLevel] = React.useState<ExclusionRisk>({ risk: 'Low Risk', color: 'green' });
    const [companyDesc, setCompanydesc] = React.useState<string>('');
    const [companiesHousesData, setCompaniesHousesData] = React.useState<CompaniesHouseInterface | null>(null);
    const [sectorApiCall, setSectorApiCall] = React.useState<boolean>(false);
    const [wikipediaApiCall, setWikipediaApiCall] = React.useState<boolean>(false);


    useEffect(() => {
        console.log('props', props.auth);
        onAnalyze(); // call to all apis : wikipedia, UI dummy data, company house data , start and poll function api 
        getFiltersCriteriaExclusions(); // get criteria data from url params
        (reactLocalStorage.getObject('authState') && reactLocalStorage.getObject('authState').isLoggedIn == true) ? console.log('auth check in else', props.auth) : props.history.push("/login"); // auth session check

        resetTimeout(); //  clears  allsetimeout var on initialization
    }, [])

    const getFiltersCriteriaExclusions = () => {
        if (params && params.criteria) { // executes if exclusion criteria are selected
            let selectionArray = params.criteria.split(','); // get selected exclusion criteria from url params
            console.log('split', selectionArray)
            let selection: ExclusionCriteriaInterface[] = [];
            selectionArray.forEach(element => {// find id and name details from exclusion criteria list array to show in sidefilters format
                let matchobj = options.filter((val) => val.name == element);
                selection.push(matchobj[0]);
            });
            selectOptions(selection);
        }
    }

    const resetTimeout = () => {
        if (apiCallTimeout)
            clearTimeout(apiCallTimeout);
        if (apiCallTimeout1)
            clearTimeout(apiCallTimeout1);
    }

    const getSectorData = () => { // set sector data on updates from poll function api response
        return Promise.resolve(setSectorData(sectorDataBehaviorSubject.getValue()));
    }
    const parseValue = (data: number) => { return `(${Math.round((data) * 10)}%)`; }; // calculaing exclusions data

    const calculateHighRisk = (val: number) => { // compare and set exclusion risk
        if (val >= 50)
            renderExclusioRisk('high');
        if (val >= 10 && val < 50)
            renderExclusioRisk('medium');
        if (val < 10)
            renderExclusioRisk('low');
    }

    const onSelectcriteria = () => { // get company sector data from poll function api and format it to map on AI setor Detection table
        let TopSectors = sectorDataBehaviorSubject.getValue()?.TopSectors;
        if (selectedOptions.length > 0 && TopSectors) {
            let numericValues: Array<any> = [];
            selectedOptions.map((data, index) => {
                let matchNameRecord = _.find(TopSectors, { sectors: data.name });
                console.log('matchNameRecord', matchNameRecord, TopSectors)
                if (matchNameRecord) numericValues.push(matchNameRecord.numericVal);
            })
            let max = _.max(numericValues); // calculate max value sector
            calculateHighRisk(max);
        }
    }

    const renderExclusioRisk = (risk: 'high' | 'medium' | 'low') => {// get exclusion risk of company sector having max value
        switch (risk) {
            case 'high': setExclusionListLevel({ risk: 'High Risk', color: 'red' })
                break;
            case 'medium': setExclusionListLevel({ risk: 'Medium Risk', color: 'amber' })
                break;
            case 'low': setExclusionListLevel({ risk: 'Low Risk', color: 'green' })
                break;
            default: setExclusionListLevel({ risk: 'Low Risk', color: 'green' })
        }
    }

    const onSignOut = async () => {
        resetTimeout();
        props.history.push('/login');
        reactLocalStorage.clear();
        Auth.signOut();
        reactLocalStorage.setObject('authState', {
            isLoggedIn: false
        })

    }

    const backToSearch = () => {
        resetTimeout();
        props.history.push('/company-search');
    }

    const resetCompanyNameUrlData = () => { // reset function on Reset Filters click in sidebar filters
        selectOptions([]); // clear the criteria select control
        setCompanyName(''); // clear company name 
        setCompanyUrl('');  // clear company url 
        resetTimeout(); // clear all settimout var
    }

    const intervalCalltoApi = (executionArndata: string) => { // call to poll function api after 5 sec till it returns data the output.
        setDataAISectorDetection([]);
        Promise.resolve(
            fetchData.apiPostPollFunction(executionArndata).then((data) => {
                if (data.status == 200) {
                    if (!data.data || !data.data.output) {
                        apiCallTimeout = setTimeout(() => {
                            intervalCalltoApi(executionArndata);
                        }, 5000)
                    } else {
                        let output: { statusCode: number, body: any } = JSON.parse(data.data.output);
                        let body = output.body;

                        if (!body || !body.exclusionRisk || !body.companySector) {
                            apiCallTimeout1 = setTimeout(() => {
                                intervalCalltoApi(executionArndata);
                            }, 5000)

                        } else {
                            // here in  poll function api response output body , contains the exclusion and sector data.
                            resetTimeout(); // clear api call time out on getting api response in output.body{}
                            let outputDetails = output.body;

                            const size = 3;
                            const key = 'numericVal';
                            const order = 'desc'
                            let top_sectors: sectorUnit[] = [];
                            for (let k in outputDetails.exclusionRisk) { // get top 3 exclusion
                                top_sectors.push({
                                    sectors: k,
                                    value: parseValue(outputDetails.exclusionRisk[k]),
                                    numericVal: Math.round(outputDetails.exclusionRisk[k] * 10),
                                    className: Math.round(outputDetails.exclusionRisk[k] * 10) >= 50 ? 'circle red'
                                        : (Math.round(outputDetails.exclusionRisk[k] * 10) >= 10 && Math.round(outputDetails.exclusionRisk[k] * 10) < 50) ? 'circle amber'
                                            : 'circle yellow'
                                })
                            }
                            let top3_sectors: sectorUnit[] = _.orderBy(top_sectors, key, order).slice(0, size);

                            if (!params.criteria && top3_sectors[0].numericVal) {
                                calculateHighRisk(top3_sectors[0].numericVal)
                            } else if (top3_sectors[0].numericVal) {
                                onSelectcriteria();
                            }
                            sectorDataBehaviorSubject.next({ Sectors: top3_sectors, TopSectors: top_sectors });
                            getSectorData(); // set top sector data
                            let dataAISector: AISectorDetection[] = [];
                            for (let key in outputDetails.companySector) {
                                // AI Sector Detection data mapping , 10 is considered as max value to calculate percent for sector detection .
                                dataAISector.push(
                                    {
                                        name: key,
                                        value: Math.round(outputDetails.companySector[key] * 10),
                                        type: Math.round(outputDetails.companySector[key] * 10) >= 8 ? 'Match'
                                            : Math.round(outputDetails.companySector[key] * 10) >= 5 && Math.round(outputDetails.companySector[key] * 10) < 8 ? 'Close Match'
                                                : 'Mis Match'
                                    }
                                )
                            }
                            setDataAISectorDetection(dataAISector);
                        }
                        setSectorApiCall(false);  // hide loader on sector and top exclusion data data sections
                    }

                }
            }).catch(error => {
                setSectorApiCall(false); // hide loader on sector and top exclusion data data sections
                console.log(' error ')
                if (error && error.response && error.response.status == 401) {
                    onSignOut();
                }
                if (error && error.message && error.response && error.response.status == 401) {
                    growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: SessionTimeOut, life: 6000 })
                }
            })
        )
    }

    const getFundExclusionData = async () => {
        await Promise.resolve(
            fetchData.apiGetFundExclusionApiUrl().then((fundExclusionData) => {
                console.log('api call', fundExclusionData);
                if (fundExclusionData.data) {
                    setFundexclusionData(fundExclusionData.data);
                }
            }).catch((error) => {
                let errmsg = '';
                setFundexclusionData(null);
                if (error && error.response && error.response.status == 401) {
                    onSignOut();
                }
                if (error && error.message && error.response) {
                    if (error.response.status != 401)
                        errmsg = error.message
                    else
                        errmsg = SessionTimeOut;
                    console.log(error)
                    growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: errmsg, life: 6000 })
                }
            })

        )
    }
    const onAnalyze = async () => {
        resetCompanyData();
        setSectorApiCall(true); // show loader on sector and top exclusion data sections
        setWikipediaApiCall(true); //  show loader on about section
        if (companyUrl) {
            spinnerSubject.next({ loading: true })
            let companyDataPromise: any = [];
            console.log(companyName, companyUrl);
            let url_1 = companyUrl.replace(/\/$/, "");
            let url = url_1.replace(/(^\w+:|^)\/\//, '');
            setCustomUrl(url);

            companyDataPromise = await Promise.all([
                getFundExclusionData(),
                callStartPollFunction(),
                getWikipediaDatabyCompanyName(),
                getCompaniesHousesData()
            ]).then(() => {
                spinnerSubject.next({ loading: false });
            }).catch(error => {
                console.log('error in profile==>', error)

                if (error.response && error.response.status == 401) {
                    onSignOut();
                }
                if (error && error.message) {
                    let errMsg = '';
                    if (error.response && error.response.status == 401) {
                        errMsg = SessionTimeOut
                    } else {
                        errMsg = error.message
                    }
                    growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: errMsg, life: 6000 })
                }
                spinnerSubject.next({ loading: false })
            })

        }
    }

    const getWikipediaDatabyCompanyName = async () => { // get company description from wikipedia api
        await new Promise(resolve => {
            fetchData.apiGetWikipediaByCompanyName(companyName).then((exclusionlistDataServices) => {
                setWikipediaApiCall(false); // hide loader on about section

                if (exclusionlistDataServices) {
                    let data: wikipedia = exclusionlistDataServices.data;
                    if (data) {
                        let desc = data.wikipedia.content;
                        setCompanydesc(desc.split('.')[0] + "." + desc.split('.')[1] + ".");
                    } else {
                        setCompanydesc('');
                    }
                }
            }).catch((error) => {
                setWikipediaApiCall(false); // hide loader on about section
                let errMsg = '';
                setCompanydesc('');
                if (error && error.response && error.response.status == 401) {
                    onSignOut();
                }
                if (error && error.message && error.response && error.response.status != 401) {
                    if (error.response && error.response.status == 401) { // unauthorised error status code 401 
                        errMsg = SessionTimeOut
                    } else {
                        errMsg = error.message
                    }
                    //notification error message
                    growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: errMsg, life: 6000 })
                }
            })
            resolve();
        })
    }
    const getCompaniesHousesData = async () => { // get company house data
        await Promise.resolve(
            fetchData.apiGetCompaniesHouseApiUrl(companyName).then((companiesHouseData) => {
                if (companiesHouseData.data)
                    setCompaniesHousesData(companiesHouseData.data);
            }).catch((error) => {
                if (error && error.response && error.response.status == 401) {
                    onSignOut();
                }
                if (error && error.message && error.response && error.response.status != 401) {
                    growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: error.message, life: 6000 })
                }
            })
        )
    }
    const callStartPollFunction = async () => { // start poll function api to get exclusionarn
        Promise.resolve(
            await fetchData.apiPostStartFunction({ url: companyUrl, name: companyName }).then((data) => {
                if (data.data && data.data.executionArn) {
                    setExecutionArn(data.data.executionArn);
                    intervalCalltoApi(data.data.executionArn); // call to poll function api
                } else { 
                    // setExecutionArn('');
                }
            }).catch((error) => {
                // setExecutionArn('');
                if (error && error.response && error.response.status == 401) {
                    onSignOut();
                }
                if (error && error.message && error.response && error.response.status != 401) {
                    console.log(error)
                    growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: SessionTimeOut, life: 6000 })
                }
            }).then((data) => {
            })
        )
    }

    const resetCompanyData = () => { // reset all company data
        setCompanydesc('');
        setCompaniesHousesData(null)
        setExclusionListLevel({ risk: 'Low Risk', color: 'green' })
        setExecutionArn('');
        setSectorData(null);
        setFundexclusionData(null);
    }

    return (
        <React.Fragment>

            <SidebarFilters {...props} currentPage={currentPage}
                companyName={companyName}
                companyUrl={companyUrl}
                onAnalyze={onAnalyze}
                selectedOptions={selectedOptions}
                selectOptions={selectOptions}
                resetCompanyNameUrlData={resetCompanyNameUrlData}
                setCompanyName={setCompanyName}
                setCompanyUrl={setCompanyUrl}
                onSelectcriteria={onSelectcriteria}
            />
            <Navbar onSignOut={onSignOut} />
            <div id="wrapper">
                <div className="main-content">
                    <div className="company_profile_outer">
                        <span className="search_back" onClick={backToSearch}><i className="fas fa-arrow-left"></i>Back to Search</span>
                        <div id="section-1" className="dcm_key_meteics_outer">
                            {/* Company House Data with company details and high exclusion risks*/}
                            <CompanyInfo
                                companyName={companyName}
                                companyUrl={companyUrl}
                                exclusionRisklevel={exclusionRisklevel}
                                companiesHousesData={companiesHousesData}
                                sectorData={sectorData}
                                companyDesc={companyDesc}
                                loadingDesc={wikipediaApiCall}
                                loadingTopExclusions={sectorApiCall}
                                onSignOut={onSignOut}
                            />

                            {/* Key Metrics Sections with static data : TO DO: API integration and data mapping */}

                            <CompanyProfileKeyMetrics />

                            {/* Fund Exclusions Section : Potential Exclusions and Actual Exclusions */}
                            <FundExclusions
                                ActualExclusionData={fundexclusionData ? fundexclusionData.fund_exclusions : null} />

                            {/* Sector Insight Section : Published Data and Data AI Sector Detection */}
                            <SectorInsights
                                sectorPublishedData={fundexclusionData ? fundexclusionData.published_class_and_description : null}
                                dataAISectorDetection={dataAISectorDetection}
                                loadingSectors={sectorApiCall}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default CompanyProfile;
