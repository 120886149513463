import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify';
import { config } from './config/config';
Amplify.configure({
  Auth: {
    region: config.AWSRegion,
    identityPoolId: config.AWSIdentityPoolId,
    userPoolId: config.UserPoolId,
    userPoolWebClientId: config.UserPoolWebClientId,
    "oauth": {}
  },
  Storage: {
    bucket: config.S3UploadBucketName,
    region: config.AWSRegion,
    identityPoolId: config.IdentityPoolId
  }
});

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
