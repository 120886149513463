import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Subject } from 'rxjs';
import { Growl } from 'primereact/growl';

// auth interfaces
export type CognitoUserSessionData = CognitoUserSession;
export const jwtToken = 'jwtToken';
export interface AuthorizationToken {
    Authorization: string;
}export interface setToken {
    jwtToken: AuthorizationToken;
}
export const authSubject: BehaviorSubject<{ isLoggedIn: boolean }> = new BehaviorSubject<{ isLoggedIn: boolean }>({ isLoggedIn: false });

// message notification behaviour subject
export const growlBehaviorSubject: BehaviorSubject<Growl | null> = new BehaviorSubject<Growl | null>(null)

// static Exclusion criteria selection data interface on company search
export interface ExclusionCriteriaInterface {
    name: string; id: number; type: 'custom' | 'predefined'
}

// company profile data interfaces
export interface PublishedDataInterface {
    class: string
    description: string
}
export interface ActualExclusionInterface {
    fund_name: string;
    listed: string;
    period: string;
    reason: string;
}
export interface FundExclusionInterface {
    fund_exclusions: ActualExclusionInterface[];
    published_class_and_description: PublishedDataInterface[];
}

interface ch {
    type: string;
    company_name: string;
    company_number: string;
    company_status: 'active' | string;
    date_of_creation: string;
}

export interface CompaniesHouseInterface {
    ch: ch
    name: string;
}

export interface wikipedia {
    name: string;
    stemmed_name: string;
    wikipedia: {
        page: string;
        content: string;
    }
}
type topExclusionFatcorsStyle = 'circle red' | 'circle amber' | 'circle yellow'
export interface sectorUnit { sectors: string; value: string, numericVal?: number, className?: topExclusionFatcorsStyle };


export interface SectorDataInterface {
    TopSectors: sectorUnit[];
    Sectors: sectorUnit[];
    // <T extends number | string>(sector: T, value: T) => [T, T][]
}

export interface CompanyInfo {
    companyName?: string, companyUrl?: string
}

export const fundExclusionDataBehaviorSubject: BehaviorSubject<FundExclusionInterface | null> = new BehaviorSubject<FundExclusionInterface | null>(null);
export const sectorDataBehaviorSubject: BehaviorSubject<SectorDataInterface | null> = new BehaviorSubject<SectorDataInterface | null>(null);
export const spinnerSubject: Subject<{ loading: boolean }> = new Subject<{ loading: boolean }>();

export type closeIcon = 'circle' | 'circle2' | '';

export interface AISectorDetection {
    name: string, value: number, type: 'Match' | 'Mis Match' | 'Close Match'
}

// company-portfolio data interfaces
export type ExclusionRisk = { risk: 'High Risk', color: 'red' } | { risk: 'Medium Risk', color: 'amber' } | { risk: 'Low Risk', color: 'green' };
export interface ExclusionRiskFilter { high: boolean, medium: boolean, low: boolean, }