import { portfolio, sectors } from './portfolioData';

// api base url
export const BaseUrl = "https://api.abydosplatform.com/";

// api end points
export const FundExclusion = "uxdata";
export const CompaniesHouse = "companieshouse";
export const Wikipedia = "wikipedia";

// portfolio dummy data
export const PortFolioData = portfolio;
export const PortfolioSectors = sectors;


// start and poll function api
export const StartFunction = "step/start";
export const PollFunction = "step/poll";

//axios headers
const ContentType = 'application/json';