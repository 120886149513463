import React, { useRef, useEffect } from 'react'; import './App.css';
import './styles/App.scss';
import { createBrowserHistory } from 'history'
import { growlBehaviorSubject, authSubject } from './components/shared/interface';
import { Growl } from 'primereact/growl';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { SpinnerComponenent } from './components/shared/spinner';
import 'react-widgets/dist/css/react-widgets.css';
import AuthRoute from './components/routes';
import { withRouter } from 'react-router-dom';
import { authSession } from './components/auth';

const customHistory = createBrowserHistory();
const App: React.FC<{}> = () => {

  useEffect(() => {
    authSession();
    console.log(authSession())    
  },[])

 

  return (
    <React.Fragment>
      <Growl
        ref={el => {
          growlBehaviorSubject.next(el)
        }}
      />
      <SpinnerComponenent />
      <AuthRoute session={authSubject.getValue().isLoggedIn} />
    </React.Fragment >
  );
}

export default withRouter(App);
