
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import * as _ from 'lodash';

interface LoadingProps {
    loading: boolean
}
export const LoadingComponenent: React.FC<LoadingProps> = (props: LoadingProps) => {
    const [count, setCount] = React.useState<Number>(3);

    return (

        <React.Fragment>
            {
                props.loading ?
                    _.times(3, () => {
                        return <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />;
                    }) : null
            }
        </React.Fragment >
    )
}
