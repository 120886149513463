import Storage from "@aws-amplify/storage";
import { Auth } from 'aws-amplify';
import * as _ from 'lodash';
import React, { useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { getExclusionCriteria, setExclusionCriteria } from '../../shared/common';
import { CreateCustomKeywordMsg, CSV, EXCEL, RequredFileTypeErrorMsg } from '../../shared/constant';
import { authSubject, ExclusionCriteriaInterface, growlBehaviorSubject, spinnerSubject } from '../../shared/interface';
import { Navbar } from '../common/Navbar';
import { SidebarFilters } from '../company-Profile/sidebar-filters/Sidebar-Filters';
import { SearchTips } from './Search-Tips';

const SearchDashboard: React.FC<any> = (props: any) => {

    const [companyName, setCompanyName] = React.useState<string>('');
    const [websiteUrl, setWebsiteUrl] = React.useState<string>('');
    const [exclusionRiskRadioButton, selectRadioButton] = React.useState<'inputName' | 'fileUpload'>('inputName')
    const [searchKeyword, setSearchKeyword] = React.useState<string>('')
    const [exclusionCriteriaAllData, getAllData] = React.useState<ExclusionCriteriaInterface[]>(
        reactLocalStorage.getObject('Exclusion_Criteria').length > 0 ?
            reactLocalStorage.getObject('Exclusion_Criteria') : []
    );
    const [filteredExclusionData, setfilteredExclusionData] = React.useState<ExclusionCriteriaInterface[]>([]);
    const [selectedExclusionData, setSelectedExclusionData] = React.useState<ExclusionCriteriaInterface[]>([]);
    const [companyInfoFile, savefileName] = React.useState<string>('');
    const [currentPage, setCurrentPage] = React.useState<'search' | 'profile'>('search');



    useEffect(() => {
        (reactLocalStorage.getObject('authState') && reactLocalStorage.getObject('authState').isLoggedIn == true) ? console.log('auth check in else', props.auth) : props.history.push("/login");
    }, [console.log('on load useeffect reload', props.auth)])

    const onSignOut = async () => {
        props.history.push('/login')
        reactLocalStorage.clear();
        Auth.signOut();
    }
    const onSearchExclusionCriteria = () => {
        let data: ExclusionCriteriaInterface[] = [];
        if (searchKeyword && exclusionCriteriaAllData.length > 0) {
            let data =
                exclusionCriteriaAllData.filter((value) => {
                    return value.name.toLocaleLowerCase().includes(searchKeyword.toLocaleLowerCase());
                })
            console.log()
            setfilteredExclusionData(data);
        } else {
            setfilteredExclusionData([]);
            let criteriaData = getExclusionCriteria();

            if (exclusionCriteriaAllData.length == 0 && criteriaData.length > 0) {
                getAllData(criteriaData);
            }
        }
    }
    const onAddExclusionCriteria = (selectedCriteria: ExclusionCriteriaInterface) => {
        if (!datafilterByname(selectedExclusionData, selectedCriteria.name)) {
            console.log(selectedCriteria);
            let data = selectedExclusionData;
            setSelectedExclusionData([...data, selectedCriteria]);
            // props.setFilters([...data, selectedCriteria]);
        }
    }
    const datafilterByname = (data: ExclusionCriteriaInterface[], name: string) => {
        if (data)
            return _.filter(data, { 'name': name }).length;
        else return
    }
    const onAddCustomExclusionCriteria = () => {
        if (!datafilterByname(exclusionCriteriaAllData, searchKeyword)) {
            if (searchKeyword && searchKeyword.trim()) {
                const customValue: ExclusionCriteriaInterface = {
                    id: exclusionCriteriaAllData.length + 1,
                    name: searchKeyword,
                    type: 'custom'
                }
                let alldata = exclusionCriteriaAllData;
                let selectedData = selectedExclusionData;

                getAllData([...alldata, customValue]);
                setfilteredExclusionData([...selectedData, customValue]);
                setExclusionCriteria([...alldata, customValue]);
            }
        }
    }
    const onRemoveExclusionCriteria = (removeCriteria: ExclusionCriteriaInterface) => {
        setSelectedExclusionData(selectedExclusionData.filter(item => item.name != removeCriteria.name));
    }

    const getFileData = async (event: any) => {
        if (event?.target?.files) {
            const files = event?.target?.files;
            if ((files[0].type == CSV || files[0].type == EXCEL)) {
                savefileName(files[0].name);
                await Storage.put(`${files[0].name}`,
                    files[0],
                    { contentType: files[0].type })
                    .then(result => {
                        console.log('result', result);
                        growlBehaviorSubject.getValue()!.show({ severity: 'success', summary: 'Success', detail: `File uploaded successully`, life: 4000 })
                        spinnerSubject.next({ loading: false });
                    })
                    .catch(err => {
                        console.log(err.response)
                        if (err.message)
                            growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: `Cannot upload file: ${err}`, life: 4000 })
                        spinnerSubject.next({ loading: false });

                    })
            } else {
                growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: RequredFileTypeErrorMsg, life: 4000 })

            }
        }
    }

    const selectRadioInputName = () => {
        console.log('selectRadioInputName')
        if (exclusionRiskRadioButton == 'fileUpload') {
            selectRadioButton('inputName');
        }
    }
    const selectRadioFileUpload = () => {
        console.log('selectRadioFileUpload')

        if (exclusionRiskRadioButton == 'inputName') {
            selectRadioButton('fileUpload');
        }
    }

    const onChangeCompanyname = (event: React.ChangeEvent<any>) => {
        selectRadioInputName();
        if (event.target.value)
            setCompanyName(event.target.value);
        else setCompanyName('');
        console.log(event.target.value, companyName)
    }
    const onChangeWebURL = (event: React.ChangeEvent<any>) => {
        selectRadioInputName();
        event.target.value ? setWebsiteUrl(event.target.value.trim()) : setWebsiteUrl('');
        console.log(event.target.value, websiteUrl)

    }
    const onAnalyze = () => {
        if (exclusionRiskRadioButton == 'inputName' && websiteUrl) {
            console.log(websiteUrl, companyName)
            let url_1 = websiteUrl.replace(/\/$/, "");
            let url = url_1.replace(/(^\w+:|^)\/\//, '');

            let criteria: string = '';
            if (selectedExclusionData.length > 0) {
                let selectedval: string[] = [];
                selectedExclusionData.map((val) => {
                    selectedval.push(val.name)
                })
                criteria = selectedval.join();
            }
            if (companyName && criteria) {
                props.history.push({
                    pathname: `/company-profile/${companyName}/companyurl=${url}/criteria=${criteria}`,
                    state: selectedExclusionData
                })
            }
            else if (companyName !== '' && criteria == '') {
                props.history.push({
                    pathname: `/company-profile/${companyName}/companyurl=${url}`,
                    state: selectedExclusionData
                })
            } else if (companyName == '' && criteria !== '') {
                props.history.push({
                    pathname: `/company-profile/companyurl=${url}/criteria=${criteria}`,
                    state: selectedExclusionData
                })
            } else {
                props.history.push({
                    pathname: `/company-profile/companyurl=${url}`,
                    state: selectedExclusionData
                })
            }


        }
        if (exclusionRiskRadioButton == 'fileUpload') {
            spinnerSubject.next({ loading: true })
            setTimeout(() => {
                props.history.push("/company-portfolio");
                spinnerSubject.next({ loading: false })
            }, 1000);
        }
    }

    return (
        <React.Fragment>

            <SidebarFilters currentPage={currentPage} {...props} filters={selectedExclusionData} />
            <Navbar onSignOut={onSignOut} />

            <div id="wrapper">
                <div className="main-content">

                    <div className="search_page_content_outer">
                        <h3 className="page_title">Exclusion Risk Analysis</h3>
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xl-8 col-lg-8 col-md-12 col_nopadding">
                                    <div className="search_left">

                                        <div className="row">
                                            {/* input company name and company url */}
                                            <div className="col-xl-5 col-lg-12 col-md-12">
                                                <div className="custom-control custom-radio custom-control-inline search_left_input">
                                                    <input type="radio" className="custom-control-input" id="customRadio" name="example" value='company-name' checked={exclusionRiskRadioButton == 'inputName'} onChange={() => {
                                                        selectRadioButton('inputName')
                                                        console.log('on selection ', exclusionRiskRadioButton)
                                                    }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customRadio" ></label>

                                                    <div className="form-group search_input">
                                                        <i className="far fa-building"></i>
                                                        <input type="email" className="form-control" id="exampleInputCompanyName" aria-describedby="emailHelp" placeholder="Company Name"
                                                            value={companyName} onChange={(event) => { onChangeCompanyname(event) }} />
                                                    </div>

                                                    <br />

                                                    <div className="form-group form_input">
                                                        <i className="fas fa-globe"></i>
                                                        <input type="email" className="form-control" id="exampleInputWebsiteUrl" aria-describedby="emailHelp" placeholder="Website URL*"
                                                            value={websiteUrl} onChange={(event) => { onChangeWebURL(event) }} />
                                                    </div>


                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-12 col-md-12 inner-or">
                                                <p>or</p>
                                            </div>

                                            {/*Drag and drop or browse csv file to upload */}

                                            <div className="col-xl-5 col-lg-12 col-md-12" >
                                                <div className="custom-control custom-radio custom-control-inline search_left_input">
                                                    <input type="radio" className="custom-control-input" id="customRadio2" name="example" value='fileUpload' checked={exclusionRiskRadioButton == 'fileUpload' ? true : false} onChange={() => {
                                                        selectRadioButton('fileUpload');
                                                    }
                                                    } />
                                                    <label className="custom-control-label" htmlFor="customRadio2"></label>

                                                    <div className="mb-3 browse_file" draggable={exclusionRiskRadioButton == 'fileUpload'}
                                                        onDragEnter={selectRadioFileUpload}>
                                                        <div className="browse_icon"><i className="fas fa-cloud-upload-alt"></i></div>
                                                        <div className="browse_input" onClick={selectRadioFileUpload}>
                                                            <span>Drag & Drop to upload</span>
                                                            <button className="btn brows_btn" disabled={exclusionRiskRadioButton == 'inputName'} >Browse File</button>
                                                            <input type="file" className="form-control-file" id="exampleFormControlFile1"
                                                                onChange={(event) => getFileData(event)} />
                                                            <label className="mb-0 mt-3">{companyInfoFile}</label>
                                                        </div>
                                                    </div>

                                                    <h6 className="text-muted">Supported file: Excel, CSV</h6>

                                                </div>
                                            </div>
                                        </div>

                                        {/* Exclusion Criteria Section */}
                                        <div className="row exclusion_criteria_outer">
                                            <div className="col-md-5">
                                                <div className="exclusion_criteria">

                                                    <h3>Exclusion Criteria</h3>

                                                    <div className="input_autotype">
                                                        <input type="text" className="form-control" placeholder='Search Exclusion Criteria' id='searckKeywordName' value={searchKeyword}
                                                            onKeyUp={onSearchExclusionCriteria}
                                                            onChange={(event) => {
                                                                setSearchKeyword(event.target.value);
                                                            }} />
                                                    </div>
                                                    {
                                                        selectedExclusionData.length > 0 ?
                                                            <div className="criteria_all_options">

                                                                <ul>
                                                                    {
                                                                        selectedExclusionData.map(((exclusionValue, index) => {
                                                                            return <li key={index}>
                                                                                <div className={exclusionValue.type == 'custom' ? 'added_option' : "all_options"}>
                                                                                    <p>{exclusionValue.name}<span className='ml-1' ><i className="fas fa-minus-circle"
                                                                                        onClick={() => {
                                                                                            onRemoveExclusionCriteria(exclusionValue);
                                                                                        }}
                                                                                    ></i></span></p>
                                                                                </div>
                                                                            </li>
                                                                        }))
                                                                    }

                                                                </ul>

                                                            </div>
                                                            : null
                                                    }

                                                    <div className="btn_analyse"><span onClick={onAnalyze}>Analyse</span></div>

                                                </div>


                                            </div>
                                            <div className="col-md-2"></div>

                                            {(filteredExclusionData.length > 0 || searchKeyword) ?

                                                <div className="col-md-5">

                                                    <div className="exclusion_criteria_right">
                                                        <div className="exclusion_criteria_right_outer">
                                                            {filteredExclusionData.length > 0 ?
                                                                filteredExclusionData.sort((a, b) => a.id < b.id ? 1 : -1).map((data, index) => {
                                                                    return <div key={index} className="select_text_box">
                                                                        <p key={index}>{data.name}<span className='ml-1'>
                                                                            <i className="fas fa-plus-circle" key={index}
                                                                                onClick={() => onAddExclusionCriteria(data)}></i></span></p>
                                                                    </div>
                                                                })
                                                                :

                                                                <div className='add-keyword-section'>
                                                                    <p className='add-keyword-message'>{CreateCustomKeywordMsg}</p>
                                                                    <div className="plus_btn"><span onClick={onAddCustomExclusionCriteria}><i className="fas fa-plus"></i></span></div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>


                                                </div> : null
                                            }


                                        </div>


                                    </div>
                                </div>

                                {/* Search Tips */}
                                <div className="col-xl-4 col-lg-4 col-md-12">
                                    <SearchTips />
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment >
    )
}

export default SearchDashboard;
