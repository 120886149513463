import React from 'react';
import { CompaniesHouseInterface, ExclusionRisk, SectorDataInterface } from '../../../shared/interface';
import { LoadingComponenent as Loader } from '../../../shared/loading';

interface CompanyHouseDataprops {
    companiesHousesData: CompaniesHouseInterface | null;
    companyName: string;
    companyUrl: string;
    companyDesc: string;
    exclusionRisklevel: ExclusionRisk;
    sectorData: SectorDataInterface | null;
    loadingDesc: boolean;
    loadingTopExclusions: boolean
    onSignOut: () => void;
}

export const CompanyInfo: React.FC<CompanyHouseDataprops> = (props: CompanyHouseDataprops) => {

    return (
        <React.Fragment>
            <div className="dcm_outer  box_content_outer">
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dcm_title">
                                    <div>{props.companiesHousesData?.name ? props.companiesHousesData.name.toLocaleUpperCase() : ''}</div>
                                    {/* <div className='type_head_second'>Media</div> 
                                                    // TO DO*/}
                                </div>

                            </div>

                            <div className="col-sm-12 col-md-5">
                                <p className="type_head">ABOUT</p>
                                <Loader loading={props.loadingDesc} />
                                <p className="type_head_second">{props.companyDesc}</p>
                            </div>

                            <div className="col-sm-12 col-md-3">
                                <p className="type_head"> <i className="fas fa-flag"></i>
                                </p>
                                <p className="type_head_second">Private</p>
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <p className="type_head"> <i className="fas fa-globe"></i>
                                </p>
                                <p className="type_head_second">{props.companyUrl}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="dcm_right_content">
                            <p>
                                <a data-tooltip="The company has a High Risk of fund exclusion.">
                                    <i className="fas fa-question-circle"></i></a>  Exclusion Risk
                              <span className={'risk ' + props.exclusionRisklevel.color}>
                                    {props.exclusionRisklevel.risk}
                                </span>
                            </p>
                        </div>

                        <div className="exclusion_outer">
                            <p className="top_exclu">Top 3 Exclusions</p>
                            <ul className="top_exclusion_text">
                                <Loader loading={props.loadingTopExclusions} />
                                {
                                    props.sectorData?.Sectors.map((val, i) => {
                                        return <li key={i} > <span className={val.className}></span>{`${val.sectors} ${val.value}`}</li>
                                    })
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}