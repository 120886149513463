import { ExclusionCriteriaInterface } from "./interface";

// Form validation mandatory fields messages
export const EmailRequiredErrorMsg = 'Please enter email address';
export const PasswordRequiredErrorMsg = 'Please enter password';
export const FirstNameRequiredErrorMsg = 'Please enter first name';
export const LastNameRequiredErrorMsg = 'Please enter last name';


//confirm password error message
export const ConfirmPasswordErrorMsg = 'Please confirm password';
export const ResetPasswordError = "Password and Confirm Password does not match";
export const VerificationCodeErr = 'Please enter verification code';

// Form validation valid fields messages
export const InvalidEmailErrorMsg = 'Invalid email';
export const InvalidPasswordErrorMsg = 'Please enter 8 or more characters for your password';
export const InvalidFirstNameErrorMsg = 'Invalid first name';
export const InvalidLastNameErrorMsg = 'Invalid last name';

//confirmation Notifications
export const signupOnSuccessMessage = `You have successfully registered. We've sent you an email. Please check on the confirmation link to verify your account and Login.`
export const resetpasswordCompletionMessage = `Your password has been successfully updated!`


// validations input tests pattern
export const NamePattern = /^[a-zA-Z]+$/;
export const EmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PasswordPattern = /.{8,}/;


// Exclusion Criteria
export const ExclusionCriteriaArray: ExclusionCriteriaInterface[] = [
    { name: 'Weapons', id: 1, type: 'predefined' },
    { name: 'Uranium', id: 2, type: 'predefined' },
    { name: 'Munition', id: 3, type: 'predefined' },
    { name: 'Casilas', id: 4, type: 'predefined' },
    { name: 'Nuclear Weapons', id: 5, type: 'predefined' },
    { name: 'Cluster Bombs', id: 6, type: 'predefined' },
    { name: 'Ammunition', id: 7, type: 'predefined' },
    { name: 'Human_Rights', id: 8, type: 'predefined' },
    { name: 'Nuclear', id: 9, type: 'predefined' }
]

// session timeout
export const SessionTimeOut = 'Session timeout';
export const SectorInsightDesc: string = "Companies are often multi-sector, which can make it difficult to evaulate a company's operational risk. Listed below are the sectors the company is listed in publicly, and our analysis of what sectors they are actually in. The risk of this company being miscategorized in funds is HIGH.";
export const RelationShipMapsDesc = "Relationship maps show the relationships of keystakeholders within the company and their connection to other entities that may be high risk.";
export const BehaviouralModelDesc = "Historical tracker showing the companies involvment in risky behaviours and how things have changed over time.";
export const FundExclusionsDesc = "Knowing whether a company is likely to be excluded from a similar fund (or is already excluded from such funds) can be a valuable tool for analsis. This company shows a HIGH risk of being excluded from other funds for these exclusion criteria.";

export const CreateCustomKeywordMsg = 'Please click on plus button to add custom keyword for exclusion criteria.';

// csv file validations
export const CSV = 'text/csv';
export const EXCEL = 'application/vnd.ms-excel';
export const RequredFileTypeErrorMsg = 'Invalid file'


// custom url patterns
export const URLRemoveTrailingSlash = /\/$/;
export const URLRemoveInitials = /(^\w+:|^)\/\//;
