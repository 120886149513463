import { Auth } from "aws-amplify";
import { CognitoUserSessionData, authSubject } from "./shared/interface";
import { reactLocalStorage } from 'reactjs-localstorage';

export const authSession = async () => {
    console.log('in app auth');
    await Auth.currentSession().then((sessionData: CognitoUserSessionData) => {
        console.log('sessionData.isValid()', sessionData.isValid())
        reactLocalStorage.setObject('authState', {
            isLoggedIn: sessionData.isValid() == true ? true : false
        })
        authSubject.next({ isLoggedIn: true })

    }).catch((error) => {
        authSubject.next({ isLoggedIn: true })
        console.log(error)
        reactLocalStorage.setObject('authState', {
            isLoggedIn: false
        })

    })
    return reactLocalStorage.getObject('authState').isLoggedIn;
}