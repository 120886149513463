import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import abydosLogo from '../../../../assets/images/abydos-logo.png';
import { ExclusionCriteriaInterface } from '../../../shared/interface';
import { MultiSelectControl } from './Multi-select';

export const SidebarFilters: React.FC<any> = (props: any) => {
    const params: {
        name?: string,
        url: string,
        criteria?: string
    } = useParams();
    const [resetFilter, setResetFilter] = React.useState<boolean>(false);
    const [companyName, setCompanyName] = React.useState<string>('');
    const [websiteUrl, setWebsiteUrl] = React.useState<string>('');
    const [companyNameInput, showCompanyInput] = React.useState<boolean>(false);
    const [weburlInput, showWebUrlInput] = React.useState<boolean>(false);
    const [selectedCriteria, setExclusionCriteriaValue] = React.useState<ExclusionCriteriaInterface[]>(
        props.selectedOptions ? props.selectedOptions : []
    )
    const [exclusionRiskRadioButton, selectRadioButton] = React.useState<'inputName' | 'fileUpload'>('inputName');
    const [options, setOptions] = React.useState<ExclusionCriteriaInterface[]>([]);

    useEffect(() => {
        showWebUrlInput(false);
        showCompanyInput(false);
        console.log('companyName', props.selectedOptions);
    }, [console.log('companyName', props.selectedOptions)])

    const resetFilters = () => {
        showCompanyInput(true);
        showWebUrlInput(true);
        setExclusionCriteriaValue([]);
        props.resetCompanyNameUrlData();
        console.log(props.companyName);
        console.log(props.companyUrl)
    }

    const onAnalyze = () => {
        if (websiteUrl) {
            props.onAnalyze();
            showCompanyInput(false);
            showWebUrlInput(false);

            let url_1 = props.companyUrl.replace(/\/$/, "");
            let url = url_1.replace(/(^\w+:|^)\/\//, '');
            let criteria = '';
            console.log('criteria===>', criteria);
            if (props.selectedOptions.length > 0) {
                let selectedval: string[] = [];
                props.selectedOptions.map((val: ExclusionCriteriaInterface) => {
                    selectedval.push(val.name)
                })
                criteria = selectedval.join();
            }
            if (props.companyName && criteria !== '') {
                props.history.push({
                    pathname: `/company-profile/${props.companyName}/companyurl=${url}/criteria=${criteria}`,
                    state: props.selectedOptions
                })
            }
            else if (props.companyName !== '' && criteria == '') {
                props.history.push({
                    pathname: `/company-profile/${props.companyName}/companyurl=${url}`,
                    state: props.selectedOptions
                })
            } else if (props.companyName == '' && criteria !== '') {
                props.history.push({
                    pathname: `/company-profile/companyurl=${url}/criteria=${criteria}`,
                    state: props.selectedOptions
                })
            } else {
                props.history.push({
                    pathname: `/company-profile/companyurl=${url}`,
                    state: props.selectedOptions
                })
            }
            setCompanyName('');
            setWebsiteUrl('');
        }

    }

    const onChangeWebUrl = (event: React.ChangeEvent<any>) => {
        if (event.target.value) {
            setWebsiteUrl(event.target.value.trim())
            props.setCompanyUrl(event.target.value.trim());
        } else {
            setWebsiteUrl('');
            props.setCompanyUrl('')
        }

    }
    const onChangeCompanyName = (event: React.ChangeEvent<any>) => {
        console.log(event.target.value)
        if (event.target.value) {
            setCompanyName(event.target.value.trim());
            props.setCompanyName(event.target.value.trim());
        } else {
            setCompanyName('');
            props.setCompanyName('');
        }
    }

    const getAllcriteria = (data: ExclusionCriteriaInterface[]) => {
        setOptions(data)
    }
    return (
        <React.Fragment>
            <div className="main-menu  main_menu_abydos">
                <div className="content">
                    <div className="my_logo">
                        <span className="logo"><img src={abydosLogo} /></span>
                        <button type="button" className="button-close js__menu_close"><i className="fas fa-times"></i></button>
                    </div>
                    {props.currentPage == 'profile' ?
                        <div className="sidee_bar">
                            <div className="filters">
                                <p>Filters</p>
                            </div>
                            <div className="side_bar_outer">
                                <h5>Company Name</h5>{
                                    !companyNameInput ?
                                        <div className="side_bar_content dcm_side_bar">
                                            <div className="dcm_criteria_all_optaion">
                                                <div className="dcm_criteria_select_optaion">
                                                    <ul>
                                                        <li>
                                                            <div className="dem_icon">
                                                                <i className="far fa-building"></i>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="selected_options_criteria">
                                                                <p>{props.companyName}<span className='ml-1'><i className="fas fa-times-circle"
                                                                    onClick={() => showCompanyInput(true)}></i></span></p>
                                                            </div>

                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>

                                        </div> :
                                        <div className="form-group search_input">
                                            {/* <i className="far fa-building"></i> */}
                                            <input type="email" className="form-control" id="exampleInputCompanyName" aria-describedby="emailHelp" placeholder="Company Name"
                                                value={props.companyName} onChange={(event) => { onChangeCompanyName(event) }} />
                                        </div>
                                }


                            </div>

                            <div className="side_bar_outer">
                                <h5>Website URL<sup>*</sup></h5>
                                {
                                    !weburlInput ?
                                        <div className="side_bar_content">
                                            <div className="dem_icon"> <i className="fas fa-globe"></i>
                                            </div>
                                            <div className="dcm_email_text">
                                                <p>{props.companyUrl}<span className='ml-1'><i className="fas fa-times-circle" onClick={() => showWebUrlInput(true)}></i></span></p>
                                            </div>
                                        </div> :
                                        <div className="form-group form_input">
                                            {/* <i className="fas fa-globe"></i> */}
                                            <input type="email" className="form-control" id="exampleInputWebsiteUrl" aria-describedby="emailHelp" placeholder="Website URL*"
                                                value={props.companyURL} onChange={(event) => onChangeWebUrl(event)} />
                                        </div>
                                }


                            </div>

                            <div className="side_bar_outer">
                                <h5>Exclusion Criteria</h5>
                                <div className="side_bar_content">
                                    <div className="sidebar_criteria_all_options">
                                        <div className="criteria_select_options side_bar_criteria">
                                            <MultiSelectControl
                                                setExclusionCriteriaOptions={(data: ExclusionCriteriaInterface[]) => {
                                                    props.selectOptions(data)
                                                    props.onSelectcriteria()
                                                }}
                                                selectedOptions={props.selectedOptions} getAllcriteria={getAllcriteria} />
                                        </div>
                                    </div>
                                </div>
                                <div className='sidebar_btn_analyse'>

                                    <div className="btn_analyse" onClick={onAnalyze}><span>Analyse</span></div>

                                </div>
                                <div className="reset_filter">
                                    <span className='mr-1' onClick={resetFilters}><i className="fas fa-times-circle"></i>Reset Filters</span>
                                </div>

                            </div>
                        </div>
                        : null}
                    <div className="navigation right_side_bar_outer">
                        {/* <!--<h5 className="title">Navigation</h5>-->
			<!-- /.title --> */}
                        <ul className="menu js__accordion">

                        </ul>
                    </div>

                </div>

            </div>
        </React.Fragment>
    )
}