import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import * as form from '../../shared/constant';
import { InputValidations } from '../../shared/input-validations';
import { growlBehaviorSubject, spinnerSubject } from '../../shared/interface';


const SignUp: React.FC<any> = (props: any) => {
    const [email, setEmail] = React.useState<string>('');
    const [isEmailValid, validateEmail] = React.useState<boolean>(false);
    const [emailErrMsg, setEmailErrorMessage] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [isPasswordValid, validatePassword] = React.useState<boolean>(false);
    const [passwordErrMsg, setPasswordErrorMessage] = React.useState<string>('');
    const [firstName, setfirstName] = React.useState<string>('');
    const [isfirstNameValid, validatefirstName] = React.useState<boolean>(false);
    const [firstNameErrMsg, setfirstNameErrorMessage] = React.useState<string>('');
    const [lastName, setlastName] = React.useState<string>('');
    const [islastNameValid, validatelastName] = React.useState<boolean>(false);
    const [lastNameErrMsg, setlastNameErrorMessage] = React.useState<string>('');
    const [disabled, formSubmitDisable] = React.useState<boolean>(true);
    const [passwordtype, chnagePasspwardType] = React.useState<'text' | 'password'>('password')

    useEffect(() => {
        console.log(props.currentState)
    }, [])


    const backToLogin = () => {
        props.history.push("/login");
    }

    const onSignUp = async (event: any) => {
        spinnerSubject.next({ loading: true })
        event.preventDefault();
        if (isEmailValid && isPasswordValid && isfirstNameValid && islastNameValid) {
            const username = email;
            const name = firstName + ' ' + lastName;
            try {
                const user = await Auth.signUp({
                    password,
                    username,
                    attributes: {
                        name: name
                    }
                }).then((data) => {
                    resetInputFields();
                    growlBehaviorSubject.getValue()!.show({ severity: 'success', summary: 'Success', detail: form.signupOnSuccessMessage, life: 10000 })
                    backToLogin();
                    spinnerSubject.next({ loading: false })
                }).catch(error => {
                    spinnerSubject.next({ loading: false });
                    growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: error.message, life: 6000 })
                });
            } catch (error) {
                growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: error.message, life: 7000 })
            }
        } else {
            console.log('error')
        }
    }

    const resetInputFields = () => {
        setEmail(''); setPassword(''); setfirstName(''); setlastName('');
    }

    const onEmailValidation = () => {
        const email_: string = email.trim().toLocaleLowerCase();
        if (!email_) {
            validateEmail(false); setEmailErrorMessage(form.EmailRequiredErrorMsg);
            formSubmitDisable(true)
        }
        else if (!form.EmailPattern.test(email_)) {
            validateEmail(false); setEmailErrorMessage(form.InvalidEmailErrorMsg);
            formSubmitDisable(true)
        }
        else {
            validateEmail(true); setEmailErrorMessage('');
            if (isEmailValid && isPasswordValid && isfirstNameValid && islastNameValid) {
                formSubmitDisable(false);
            }
        }
    }

    const onFirstNameValidation = () => {
        const firstName_ = firstName.trim().toLocaleLowerCase();

        if (!firstName_) {
            validatefirstName(false); setfirstNameErrorMessage(form.FirstNameRequiredErrorMsg);
            formSubmitDisable(true)
        } else if (!form.NamePattern.test(firstName_)) {
            validatefirstName(false); setfirstNameErrorMessage(form.InvalidFirstNameErrorMsg);
            formSubmitDisable(true)
        } else {
            validatefirstName(true); setfirstNameErrorMessage('');
            if (isEmailValid && isPasswordValid && isfirstNameValid && islastNameValid) {
                formSubmitDisable(false);
            }
        }
    }
    const onLastNameValidation = () => {
        const lastName_ = lastName.trim().toLocaleLowerCase();

        if (!lastName_) {
            validatelastName(false); setlastNameErrorMessage(form.LastNameRequiredErrorMsg);
            formSubmitDisable(true)
        } else if (!form.NamePattern.test(lastName_)) {
            validatelastName(false); setlastNameErrorMessage(form.InvalidLastNameErrorMsg);
            formSubmitDisable(true)
        } else {
            validatelastName(true); setlastNameErrorMessage('');
            if (isEmailValid && isPasswordValid && isfirstNameValid && islastNameValid) {
                formSubmitDisable(false);
            }
        }
    }
    const onPasswordValidation = () => {
        const password_: string = password.trim().toLocaleLowerCase()
        if (!password_) {
            validatePassword(false); setPasswordErrorMessage(form.PasswordRequiredErrorMsg);
            formSubmitDisable(true);
        }
        else if (!form.PasswordPattern.test(password_)) {
            validatePassword(false); setPasswordErrorMessage(form.InvalidPasswordErrorMsg);
            formSubmitDisable(true);
        }
        else {
            validatePassword(true); setPasswordErrorMessage('');
            if (isEmailValid && isPasswordValid && isfirstNameValid && islastNameValid) {
                formSubmitDisable(false);
            }
        }
    }


    return (
        <React.Fragment>
            <div id="wrapper">
                <div className="login_wrapper">
                    <div className="login_outer">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="close_button text-right">
                                <span style={{ fontSize: "10px", color: "#f00", width: '14px' }} >
                                    <i className="fas fa-times" onClick={backToLogin}></i>
                                </span>
                            </div>
                            <h3 className="text-center">Sign Up into <span>Abydos</span></h3>

                            <form>
                                <React.Fragment>
                                    <div className="form-group form_input">
                                        <i className="fas fa-user"></i>
                                        <input type="text" className="form-control" required placeholder="Firstname*"
                                            value={firstName} onChange={(event) => {
                                                setfirstName(event.target.value ? event.target.value.trim() : '')
                                            }} onKeyUp={onFirstNameValidation} />
                                        <InputValidations isError={isfirstNameValid} errMessage={firstNameErrMsg} />
                                    </div>
                                    <div className="form-group form_input">
                                        <i className="fas fa-user"></i>
                                        <input type="text" className="form-control" placeholder="LastName*"
                                            value={lastName} onChange={(event) => {
                                                setlastName(event.target.value ? event.target.value.trim() : '')
                                            }} onKeyUp={onLastNameValidation} />
                                        <InputValidations isError={islastNameValid} errMessage={lastNameErrMsg} />

                                    </div>
                                    <div className="form-group form_input">
                                        <i className="fas fa-envelope"></i>
                                        <input type="text" className="form-control" placeholder="Email*"
                                            value={email} onChange={(event) => {
                                                setEmail(event.target.value ? event.target.value.trim() : '')
                                            }} onKeyUp={onEmailValidation} />
                                        <InputValidations isError={isEmailValid} errMessage={emailErrMsg} />

                                    </div>

                                    <div className="form-group form_input">
                                        <i className="fas fa-lock"></i>
                                        <div className="show_pw text-right"><span>
                                            {passwordtype == 'text' ? <i className="far fa-eye" onClick={() => chnagePasspwardType('password')}></i> : <i className="far fa-eye-slash" onClick={() => chnagePasspwardType('text')}></i>}
                                        </span></div>
                                        <input type={passwordtype} className="form-control" id="signUpInputPassword" placeholder="Password*"
                                            value={password} onChange={(event) => {
                                                setPassword(event.target.value ? event.target.value.trim() : '')
                                            }} onKeyUp={onPasswordValidation} />
                                        <InputValidations isError={isPasswordValid} errMessage={passwordErrMsg} />


                                    </div>

                                    <button type="submit" className="btn btn_login" disabled={disabled} onClick={onSignUp}><span>Sign Up</span></button>

                                </React.Fragment>

                                <button type="submit" className="btn btn_signin" onClick={backToLogin}><span >Sign In</span></button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default SignUp;