import React from 'react';
import { useParams } from 'react-router-dom';
import 'react-widgets/dist/css/react-widgets.css';
import Multiselect from 'react-widgets/lib/Multiselect';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ExclusionCriteriaInterface } from '../../../shared/interface';
import { setExclusionCriteria } from '../../../shared/common';

interface closeIconProps {
    selectedOptions: ExclusionCriteriaInterface[];
    setExclusionCriteriaOptions: (val: ExclusionCriteriaInterface[]) => void;
    // options: ExclusionCriteriaInterface[];
}

export const MultiSelectControl: React.FC<any> = (props: any) => {
    const params: {
        name?: string,
        url: string,
        criteria?: string
    } = useParams()
    const getAllcriteria = () => {
        return options;
    }
    const [options, getAllOptions] = React.useState<ExclusionCriteriaInterface[]>(
        reactLocalStorage.getObject('Exclusion_Criteria').length > 0 ?
            reactLocalStorage.getObject('Exclusion_Criteria') : []
    );
    const [selectedOptions, selectOptions] = React.useState<ExclusionCriteriaInterface[]>(props?.selectedOptions ? props.selectedOptions : []);

    return (

        <Multiselect
            data={options}
            onCreate={(newVal: string) => {
                options.push({
                    id: options.length + 1,
                    name: newVal,
                    type: 'custom'
                });
                setExclusionCriteria(options);
            }}
            allowCreate="onFilter"
            // onSelect={(val: string) => {
            //     console.log('select val===>', val);
            // }}
            value={props?.selectedOptions}
            valueField={'id'}
            onChange={(val: ExclusionCriteriaInterface[], data: any) => {
                props?.setExclusionCriteriaOptions([...val])
            }}
            messages={{
                createOption: 'Create custom keyword'
            }}
            textField='name'
        />

    )
}