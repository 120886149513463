import React from 'react';
import { Auth } from 'aws-amplify';

interface NavbarInterface {
    onSignOut: () => void;
}
export const Navbar: React.FC<NavbarInterface> = (props: NavbarInterface) => {
    const [userName, setUserName] = React.useState<string>('');
    Auth.currentUserInfo().then((data) => {
        if (data) {
            setUserName(data?.attributes?.name);
        }
    });
    return (
        <React.Fragment>
            <div className="fixed-navbar my_fixnavbar">
                <div className="pull-left">
                    <button type="button" className="menu-mobile-button js__menu_mobile"> <i className="fas fa-bars"></i></button>
                </div>

                <div className="pull-left top_bar">
                    <p><i className="fas fa-cloud"></i>
			    Good Afternoon,
			    <span>{userName}</span>
                    </p>
                </div>

                <div className="pull-right topbar_right">
                    <i className="fas fa-cog"></i>
                    <button className="sign_out_btn" type="button"><span onClick={props.onSignOut}>Sign out</span></button>

                </div>


                <div className="pull-right dropdown show setting_outer">
                    <a className="dash_settings dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-cog"></i>
                    </a>

                    <div className="dropdown-menu settings_content" aria-labelledby="dropdownMenuLink">
                        <span className="dropdown-item" onClick={props.onSignOut}><i className="fas fa-sign-out-alt"></i>Sign out</span>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}