import { Auth } from "aws-amplify";
import React, { useEffect } from "react";
import * as form from "../../shared/constant";
import { resetpasswordCompletionMessage } from "../../shared/constant";
import { InputValidations } from "../../shared/input-validations";
import { growlBehaviorSubject, spinnerSubject } from "../../shared/interface";

const ForgotPassword: React.FC<any> = (
  props: any
) => {
  const [email, setEmail] = React.useState<string>("");
  const [isEmailValid, validateEmail] = React.useState<boolean>(false);
  const [emailErrMsg, setEmailErrorMessage] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [isPasswordValid, validatePassword] = React.useState<boolean>(false);
  const [passwordErrMsg, setPasswordErrorMessage] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [isConfirmPasswordValid, validateConfirmPassword] = React.useState<boolean>(false);
  const [
    confirmPasswordErrMsg,
    setConfirmPasswordErrorMessage,
  ] = React.useState<string>("");
  const [verificationCode, setverificationCode] = React.useState<string>("");
  const [isVerificationCodeValid, validateVerificationCode] = React.useState<
    boolean
  >(false);
  const [verificationCodeErrMsg, setCodeErrorMessage] = React.useState<string>(
    ""
  );
  const [disabledForm1, formSubmitDisable1] = React.useState<boolean>(true);
  const [disabledForm2, formSubmitDisable2] = React.useState<boolean>(false);
  const [passwordtype, chnagePasspwardType] = React.useState<"text" | "password">("password");
  const [confirmPasswordtype, chnageConfirmPasspwardType] = React.useState<"text" | "password">("password");

  const [formType, showFormType] = React.useState<"confirm-email" | "reset-password-confirmation" | "confirm-password">("confirm-email")

  useEffect(() => {
  })

  const resetPassword = async (event: any) => {
    spinnerSubject.next({ loading: true });
    event.preventDefault();
    const code = verificationCode;
    if (isPasswordValid && isConfirmPasswordValid) {
      try {
        await Auth.forgotPasswordSubmit(email, code, password).then((data) => {

          const password_: string = password.trim().toLocaleLowerCase();
          const confirmpassword_: string = confirmPassword.trim().toLocaleLowerCase();

          if (password_ === confirmpassword_) {
            showFormType("reset-password-confirmation");
          }
          else {
            setConfirmPasswordErrorMessage(form.ResetPasswordError);
          }
          spinnerSubject.next({ loading: false })
          // growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: 'Your password has been successfully updated!', life: 6000 })
        });
      } catch (error) {
        growlBehaviorSubject
          .getValue()!
          .show({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: 6000,
          });
        spinnerSubject.next({ loading: false });
      }
    }
  };

  const confirmPass = (event: any) => {
    setConfirmPassword(
      event.target.value ? event.target.value.trim() : ""
    )
    onConfirmPasswordValidation();
  }

  const onConfirmEmail = async (event: any) => {
    spinnerSubject.next({ loading: true });
    event.preventDefault();
    if (isEmailValid) {
      Auth.forgotPassword(email)
        .then((data) => {
          console.log(data);
          showFormType("confirm-password");
          growlBehaviorSubject
            .getValue()!
            .show({
              severity: "success",
              summary: "Success",
              detail:
                "Verification code has been sent to your email id. Please check your email",
              life: 6000,
            });
          spinnerSubject.next({ loading: false })
        })
        .catch((error) => {
          console.log(error);
          growlBehaviorSubject
            .getValue()!
            .show({
              severity: "error",
              summary: "Error",
              detail: error.message,
              life: 6000,
            });
          spinnerSubject.next({ loading: false })
        });
    }
  };

  const backToLogin = () => {
    props.history.push("/login");
  };

  const onEmailValidation = () => {
    const email_: string = email.trim().toLocaleLowerCase();
    if (!email_) {
      validateEmail(false);
      setEmailErrorMessage(form.EmailRequiredErrorMsg);
      formSubmitDisable1(true);
    } else if (!form.EmailPattern.test(email_)) {
      validateEmail(false);
      setEmailErrorMessage(form.InvalidEmailErrorMsg);
      formSubmitDisable1(true);
    } else {
      validateEmail(true);
      setEmailErrorMessage("");
      if (isEmailValid) {
        formSubmitDisable1(false);
      }
    }
  };

  const confirmPasswordCheck = () => {
    const password_: string = password.trim().toLocaleLowerCase();
    const confirmpassword_: string = confirmPassword.trim().toLocaleLowerCase();
    if (password_ != confirmpassword_) {
      validateConfirmPassword(false);
      setConfirmPasswordErrorMessage(form.ResetPasswordError);
      formSubmitDisable2(true);
    } else {
      validateConfirmPassword(true);
      setConfirmPasswordErrorMessage("");
      formSubmitDisable2(false);
    }
  };

  const onPasswordValidation = () => {
    const password_: string = password.trim().toLocaleLowerCase();
    if (!password_) {
      validatePassword(false);
      setPasswordErrorMessage(form.PasswordRequiredErrorMsg);
      formSubmitDisable2(true);
    } else if (!form.PasswordPattern.test(password_)) {
      validatePassword(false);
      setPasswordErrorMessage(form.InvalidPasswordErrorMsg);
      formSubmitDisable2(true);
    } else {
      validatePassword(true);
      setPasswordErrorMessage("");
      if (
        isPasswordValid &&
        isVerificationCodeValid &&
        isConfirmPasswordValid
      ) {
        formSubmitDisable2(false);
      }
    }
    if (confirmPassword && password) {
      confirmPasswordCheck();
    }
  };

  const onConfirmPasswordValidation = () => {
    const confirmPassword_: string = confirmPassword.trim().toLocaleLowerCase();
    if (!confirmPassword_) {
      validateConfirmPassword(false);
      setConfirmPasswordErrorMessage(form.ConfirmPasswordErrorMsg);
      formSubmitDisable2(true);
    } else {
      validateConfirmPassword(true);
      setConfirmPasswordErrorMessage("");
      const password_: string = password.trim().toLocaleLowerCase();
      if (
        isPasswordValid &&
        isVerificationCodeValid &&
        isConfirmPasswordValid &&
        password_ === confirmPassword_
      ) {
        formSubmitDisable2(false);
      }
    }
    if (password && confirmPassword) {
      confirmPasswordCheck();
    }

  };

  const onVerificationCodeValidation = () => {
    const code: string = verificationCode.trim().toLocaleLowerCase();
    if (!code) {
      validateVerificationCode(false);
      setCodeErrorMessage(form.VerificationCodeErr);
      formSubmitDisable2(true);
    } else {
      validateVerificationCode(true);
      setCodeErrorMessage("");
      if (
        isPasswordValid &&
        isVerificationCodeValid &&
        isConfirmPasswordValid
      ) {
        formSubmitDisable2(false);
      }
    }
    confirmPasswordCheck();
  };

  return (
    <React.Fragment>
      <div id="wrapper">
        <div className="login_wrapper">
          <div className="login_outer">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="close_button text-right">
                <span
                  style={{ fontSize: "10px", color: "#f00", width: "14px" }}
                >
                  <i className="fas fa-times" onClick={backToLogin}></i>
                </span>
              </div>
              <h3 className="text-center">Reset Password</h3>

              <form>
                {formType == "confirm-email" ? (
                  <React.Fragment>
                    <div className="form-group form_input">
                      <i className="fas fa-envelope"></i>
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Email*"
                        value={email}
                        onChange={(event) =>
                          setEmail(
                            event.target.value ? event.target.value.trim() : ""
                          )
                        }
                        onKeyUp={onEmailValidation}
                      />
                      <InputValidations
                        isError={isEmailValid}
                        errMessage={emailErrMsg}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn_login"
                      disabled={disabledForm1}
                      onClick={onConfirmEmail}
                    >
                      {" "}
                      <span>Confirm Email</span>
                    </button>
                  </React.Fragment>
                ) : null}
                {formType == "confirm-password" ? (
                  <React.Fragment>
                    <div className="form-group form_input">
                      <i className="fas fa-lock"></i>
                      <div className="show_pw text-right">
                        <span></span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="inputVerficationCode"
                        placeholder="Verification Code*"
                        value={verificationCode}
                        maxLength={6}
                        onChange={(event) =>
                          setverificationCode(
                            event.target.value ? event.target.value.trim() : ""
                          )
                        }
                        onKeyUp={onVerificationCodeValidation}
                      />
                      <InputValidations
                        isError={isVerificationCodeValid}
                        errMessage={verificationCodeErrMsg}
                      />
                    </div>

                    <div className="form-group form_input">
                      <i className="fas fa-lock"></i>
                      <div className="show_pw text-right">
                        <span>
                          {passwordtype == "text" ? (
                            <i
                              className="far fa-eye"
                              onClick={() => chnagePasspwardType("password")}
                            ></i>
                          ) : (
                              <i
                                className="far fa-eye-slash"
                                onClick={() => chnagePasspwardType("text")}
                              ></i>
                            )}
                        </span>
                      </div>
                      <input
                        type={passwordtype}
                        className="form-control"
                        id="inputPassword1"
                        placeholder="New Password*"
                        value={password}
                        onChange={(event) =>
                          setPassword(
                            event.target.value ? event.target.value.trim() : ""
                          )
                        }
                        onKeyUp={onPasswordValidation}
                      />
                      <InputValidations
                        isError={isPasswordValid}
                        errMessage={passwordErrMsg}
                      />
                    </div>

                    <div className="form-group form_input">
                      <i className="fas fa-lock"></i>
                      <div className="show_pw text-right">
                        <span>
                          {confirmPasswordtype == "text" ? (
                            <i
                              className="far fa-eye"
                              onClick={() =>
                                chnageConfirmPasspwardType("password")
                              }
                            ></i>
                          ) : (
                              <i
                                className="far fa-eye-slash"
                                onClick={() => chnageConfirmPasspwardType("text")}
                              ></i>
                            )}
                        </span>
                      </div>
                      <input
                        type={confirmPasswordtype}
                        className="form-control"
                        id="inputPassword2"
                        placeholder="Confirm Password*"
                        value={confirmPassword}
                        onChange={confirmPass}
                      // onKeyUp={onConfirmPasswordValidation}
                      />
                      <InputValidations
                        isError={isConfirmPasswordValid}
                        errMessage={confirmPasswordErrMsg}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn_login w-100"
                      // disabled={disabledForm2}
                      onClick={resetPassword}
                    >
                      <span>Confirm Password</span>
                    </button>
                  </React.Fragment>
                ) : null}
                {formType == "reset-password-confirmation" ? (
                  <div>
                    <h4> {resetpasswordCompletionMessage}</h4>
                  </div>
                ) : null}

                <button
                  type="submit"
                  className="btn btn_signin"
                  onClick={backToLogin}
                >
                  Sign In
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
