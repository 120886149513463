// AWS config file


export const config={
    S3UploadBucketName : 'abydos-csv-uploads',
    AWSRegion : 'eu-west-1',
    IdentityPoolId : 'eu-west-1:414bdbca-c062-445d-9409-a1229c7f2648',
    AWSIdentityPoolId: "eu-west-1:d2567271-ddec-4a49-8b4e-d7f1ffa3efeb",
    UserPoolId: "eu-west-1_DVB8dsiF1",
    UserPoolWebClientId: "1ehgkdhm60o9n2621t0lb39bp2"
}