import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { spinnerSubject } from './interface';

export const SpinnerComponenent: React.FC = () => {
    const [spinner, showSpinner] = React.useState<boolean>(false);
    useEffect(() => {
        spinnerSubject.subscribe((val) => {
            showSpinner(val.loading);
        })
    })
    return (

        <React.Fragment>
            {
                spinner ?
                    <div className="loader-overlay">
                        <Spinner animation="border" role="status"></Spinner>
                    </div>
                    : null
            }
        </React.Fragment>
    )
}