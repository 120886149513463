import React from 'react';

interface ErrorInterface {
    errMessage: string
    isError: boolean
}

export const InputValidations: React.FC<ErrorInterface> = (error: ErrorInterface) => {
   
    return (
        <span className={!error.isError ? 'error-input-show' : 'hide'}>{error.errMessage}</span>
    )
}