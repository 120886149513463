import { reactLocalStorage } from 'reactjs-localstorage';
import { ExclusionCriteriaInterface } from './interface';


export const getExclusionCriteria = () => {
    if (reactLocalStorage.getObject('Exclusion_Criteria')) {
        return reactLocalStorage.getObject('Exclusion_Criteria');
    } else
        return [];
}
export const setExclusionCriteria = (data: ExclusionCriteriaInterface[]) => reactLocalStorage.setObject('Exclusion_Criteria', data);