

export const sectors = [
  {
    "id": 1,
    "sectors":
      "Aerospace"
  },
  {
    "id": 2,
    "sectors":
      "Weapons"
  },
  {
    "id": 3,
    "sectors":
      "Transportation"
  },
  {
    "id": 4,
    "sectors":
      "Manufacturing"
  }
]

export const portfolio = [
  {
    "id": 1,
    "company": "BBC",
    "sectors": [
      "Aerospace",
      "Weapons"
    ],
    "sector_mismatch": 2,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 1
  },
  {
    "id": 2,
    "company": "Airbus",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 0,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 0
  },
  {
    "id": 3,
    "company": "Airbus SE",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 1,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 2
  },
  {
    "id": 4,
    "company": "Honeywell",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 4,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 0
  },
  {
    "id": 5,
    "company": "jacobs engineering group inc",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 0,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 3
  },
  {
    "id": 6,
    "company": "northrop",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 3,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 0
  },
  {
    "id": 7,
    "company": "BHP Billiton",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 0,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 0
  },
  {
    "id": 8,
    "company": "Telefonica",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 2,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 0
  },
  {
    "id": 9,
    "company": "ExxonMobil",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 0,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 1
  },
  {
    "id": 10,
    "company": "Chevron",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 2,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 0
  },
  {
    "id": 11,
    "company": "E. ON",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 0,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 4
  },
  {
    "id": 12,
    "company": "Lockheed Martin",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 0,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 0
  },
  {
    "id": 13,
    "company": "BAE Systems",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 0,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 1
  },
  {
    "id": 14,
    "company": "Aurora Cannabis Inc.",
    "sectors": [
      "Transportation",
      "Manufacturing"
    ],
    "sector_mismatch": 2,
    "exclusion_risk": [
      {
        "level": "High",
        "criteria": "Cluster Munitions"
      },
      {
        "level": "Medium",
        "criteria": "Banned Weapons"
      },
      {
        "level": "Low",
        "criteria": "Nuclear Weapons"
      }
    ],
    "known_exclusions": 0
  },
  // {
  //   "id": 15,
  //   "company": "Airbus SE",
  //   "sectors": [
  //     "Transportation",
  //     "Manufacturing"
  //   ],
  //   "sector_mismatch": 0,
  //   "exclusion_risk": [
  //     {
  //       "level": "High",
  //       "criteria": "Cluster Munitions"
  //     },
  //     {
  //       "level": "Medium",
  //       "criteria": "Banned Weapons"
  //     },
  //     {
  //       "level": "Low",
  //       "criteria": "Nuclear Weapons"
  //     }
  //   ],
  //   "known_exclusions": 2
  // },
  // {
  //   "id": 16,
  //   "company": "Airbus SE",
  //   "sectors": [
  //     "Transportation",
  //     "Manufacturing"
  //   ],
  //   "sector_mismatch": 1,
  //   "exclusion_risk": [
  //     {
  //       "level": "High",
  //       "criteria": "Cluster Munitions"
  //     },
  //     {
  //       "level": "Medium",
  //       "criteria": "Banned Weapons"
  //     },
  //     {
  //       "level": "Low",
  //       "criteria": "Nuclear Weapons"
  //     }
  //   ],
  //   "known_exclusions": 0
  // },
  // {
  //   "id": 17,
  //   "company": "Company abc",
  //   "sectors": [
  //     "Transportation",
  //     "Manufacturing"
  //   ],
  //   "sector_mismatch": 0,
  //   "exclusion_risk": [
  //     {
  //       "level": "High",
  //       "criteria": "Cluster Munitions"
  //     },
  //     {
  //       "level": "Medium",
  //       "criteria": "Banned Weapons"
  //     },
  //     {
  //       "level": "Low",
  //       "criteria": "Nuclear Weapons"
  //     }
  //   ],
  //   "known_exclusions": 0
  // },
  // {
  //   "id": 18,
  //   "company": "Company def",
  //   "sectors": [
  //     "Transportation",
  //     "Manufacturing"
  //   ],
  //   "sector_mismatch": 1,
  //   "exclusion_risk": [
  //     {
  //       "level": "High",
  //       "criteria": "Cluster Munitions"
  //     },
  //     {
  //       "level": "Medium",
  //       "criteria": "Banned Weapons"
  //     },
  //     {
  //       "level": "Low",
  //       "criteria": "Nuclear Weapons"
  //     }
  //   ],
  //   "known_exclusions": 2
  // },
  // {
  //   "id": 19,
  //   "company": "Company xyz",
  //   "sectors": [
  //     "Transportation",
  //     "Manufacturing"
  //   ],
  //   "sector_mismatch": 1,
  //   "exclusion_risk": [
  //     {
  //       "level": "High",
  //       "criteria": "Cluster Munitions"
  //     },
  //     {
  //       "level": "Medium",
  //       "criteria": "Banned Weapons"
  //     },
  //     {
  //       "level": "Low",
  //       "criteria": "Nuclear Weapons"
  //     }
  //   ],
  //   "known_exclusions": 1
  // },

];