import React from 'react';
import abydosLogo from '../../../../assets/images/abydos-logo.png';
import { ExclusionRiskFilter } from '../../../shared/interface';

interface FilterProps {
    companySearchkey: string;
    sectorList: { id: number, sectors: string }[];
    sectorMisMatchCheck: boolean;
    // exclusionLowRiskFilter: boolean;
    // exclusionMediumRiskFilter: boolean;
    // exclusionHighRiskFilter: boolean;
    misCategoriseCheck: boolean;
    knownExclusions: boolean;
    onCompanySearch: (event: React.ChangeEvent<any>) => void;
    onSelectSector: (event: React.ChangeEvent<any>) => void
    // onCheckExclusion: (ExclusionRisk: 'High' | 'Medium' | 'Low') => void;
    onToggleSectorMisMatchCheck: () => void;
    onToggleMiscategoriseCheck: () => void;
    onToggleKnownExclusionCheck: () => void;
    onFilter: () => void;
}

export const FilterTable: React.FC<FilterProps> = (filterProps: FilterProps) => {
    
    const [exclusionRiskCheck, setExclusionRiskCheck] = React.useState<ExclusionRiskFilter>({
        high: false,
        medium: false,
        low: false
    })

    const onCheckExclusion = (inputCheck: 'high' | 'medium' | 'low') => {
        switch (inputCheck) {
            case 'high': setExclusionRiskCheck({
                high: !exclusionRiskCheck.high,
                medium: exclusionRiskCheck.medium,
                low: exclusionRiskCheck.low
            })
                break;
            case 'medium': setExclusionRiskCheck({
                high: exclusionRiskCheck.high,
                medium: !exclusionRiskCheck.medium,
                low: exclusionRiskCheck.low

            })
                break;
            case 'low': setExclusionRiskCheck({
                high: exclusionRiskCheck.high,
                medium: exclusionRiskCheck.medium,
                low: !exclusionRiskCheck.low
            })
                break;
            default: setExclusionRiskCheck({
                high: false,
                medium: false,
                low: false
            })
        }
    }
    
    return (
        <React.Fragment>
            <div className="main-menu  main_menu_abydos">
                <div className="content">
                    <div className="my_logo">
                        <span className="logo"><img src={abydosLogo} /></span>
                        <button type="button" className="button-close js__menu_close"><i className="fas fa-times"></i></button>
                    </div>

                    <div className="sidee_bar">
                        <div className="filters">
                            <p>Filters</p>
                        </div>
                        <div className="side_bar_outer filter">
                            <div className="form-group company-search position-relative">
                                <span className="input-icon"><i className="fas fa-search"></i></span>
                                <input type="text" className="form-control pl-5" placeholder="Company"
                                    value={filterProps.companySearchkey}
                                    onChange={(event) => {
                                        filterProps.onCompanySearch(event)
                                    }}
                                />

                            </div>
                            <div className='form-group'>
                                <select className="form-control" onChange={(event) => filterProps.onSelectSector(event)}>
                                    <option selected value={''}>All sectors</option>
                                    {
                                        filterProps.sectorList.map((sectorData: { id: number, sectors: string }) => {
                                            return <option key={sectorData.id} value={sectorData.sectors}>{sectorData.sectors}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className='form-group'>
                                <div>

                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1"
                                            checked={filterProps.sectorMisMatchCheck} onClick={filterProps.onToggleSectorMisMatchCheck} />
                                        <label className="custom-control-label" htmlFor="customCheck1">Sector Mismatch</label>
                                    </div>
                                </div>
                            </div>
                            <div className='seprator'></div>
                            <div className="exclusion-risk-filter">
                                <h5>Exclusion Risk</h5>

                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customCheck2"
                                        checked={exclusionRiskCheck.high} onClick={() => onCheckExclusion('high')}
                                    />
                                    <label className="custom-control-label" htmlFor="customCheck2">High</label>
                                </div>
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customCheck3"
                                        checked={exclusionRiskCheck.medium} onClick={() => onCheckExclusion('medium')}
                                    />
                                    <label className="custom-control-label" htmlFor="customCheck3">Medium</label>
                                </div>
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customCheck4"
                                        checked={exclusionRiskCheck.low} onClick={() => onCheckExclusion('low')}
                                    />
                                    <label className="custom-control-label" htmlFor="customCheck4">Low</label>
                                </div>
                            </div>

                            <div className='seprator'></div>

                            <div className="miscategorise-filter">
                                <div className="custom-control custom-checkbox custom-control-inline mb-0">
                                    <input type="checkbox" className="custom-control-input" id="customCheck5"
                                        checked={filterProps.misCategoriseCheck} onClick={filterProps.onToggleMiscategoriseCheck} />
                                    <label className="custom-control-label" htmlFor="customCheck5">Miscategorise</label>
                                </div>
                            </div>
                            <div className='seprator'></div>


                            <div className="known-exclusions-filter">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customCheck6"
                                        checked={filterProps.knownExclusions} onClick={filterProps.onToggleKnownExclusionCheck} />
                                    <label className="custom-control-label" htmlFor="customCheck6">Known Exclusions</label>
                                </div>
                            </div>
                        </div>

                        <div className='sidebar_btn_analyse'>
                            <div className="btn_analyse" onClick={filterProps.onFilter}><span>FILTER</span></div>
                        </div>
                        <div className="navigation right_side_bar_outer">

                            <ul className="menu js__accordion">

                            </ul>
                        </div>

                    </div>

                </div >
            </div>
        </React.Fragment >
    )
}