import { Auth } from 'aws-amplify';
import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { setExclusionCriteria } from '../../shared/common';
import * as form from '../../shared/constant';
import { InputValidations } from '../../shared/input-validations';
import { authSubject, growlBehaviorSubject, jwtToken, spinnerSubject } from '../../shared/interface';


const SignIn: React.FC<{}> = (props: any) => {
    const [email, setEmail] = React.useState<string>('')
    const [isEmailValid, validateEmail] = React.useState<boolean>(false);
    const [emailErrMsg, setEmailErrorMessage] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('')
    const [isPasswordValid, validatePassword] = React.useState<boolean>(false);
    const [passwordErrMsg, setPasswordErrorMessage] = React.useState<string>('');
    const [disabled, formSubmitDisable] = React.useState<boolean>(true);
    const [passwordtype, chnagePasspwardType] = React.useState<'text' | 'password'>('password')


    const onLoginPage = () => {
        props.history.push("/login");
    }
    const onSignUpPage = () => {
        props.history.push("/sign-up");
    }
    const onForgotPasswordPage = () => {
        props.history.push("/forgot-password");
    }


    const onSignIn = async () => {
        if (isEmailValid && isPasswordValid) {
            spinnerSubject.next({ loading: true })
            try {
                Auth.signIn(email, password).then((cognitoUser: any) => {
                    if (cognitoUser) {
                        Auth.currentSession().then(res => {
                            let idToken = res.getIdToken();
                            let jwt = idToken.getJwtToken();
                            reactLocalStorage.setObject(jwtToken, {
                                'Authorization': jwt
                            })
                            setExclusionCriteria(form.ExclusionCriteriaArray);
                            authSubject.next({
                                isLoggedIn: true
                            });
                            reactLocalStorage.setObject('authState', {
                                isLoggedIn: true
                            })
                        })
                        setTimeout(() => {
                            props.history.push("/company-search");
                            spinnerSubject.next({ loading: false })
                        }, 5000);
                    }
                }).catch(error => {
                    spinnerSubject.next({ loading: false })
                    growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: error.message, life: 6000 })
                })
            } catch (error) {
                growlBehaviorSubject.getValue()!.show({ severity: 'error', summary: 'Error', detail: error.message, life: 6000 })

            }
        }
    }

    const onEmailValidation = () => {
        const email_: string = email.trim().toLocaleLowerCase();
        if (!email_) {
            validateEmail(false); setEmailErrorMessage(form.EmailRequiredErrorMsg);
            formSubmitDisable(true)
        }
        else if (!form.EmailPattern.test(email_)) {
            validateEmail(false); setEmailErrorMessage(form.InvalidEmailErrorMsg);
            formSubmitDisable(true)
        }
        else {
            validateEmail(true); setEmailErrorMessage('');
            if (isEmailValid == true && isPasswordValid == true) {
                formSubmitDisable(false);
            }
        }
    }

    const onPasswordValidation = () => {
        const password_: string = password.trim().toLocaleLowerCase()
        if (!password_) {
            validatePassword(false); setPasswordErrorMessage(form.PasswordRequiredErrorMsg);
            formSubmitDisable(true)
        }
        else {
            validatePassword(true); setPasswordErrorMessage('');
            if (isEmailValid == true && isPasswordValid == true) {
                formSubmitDisable(false);
            }
        }
    }

    const handleKeyEnter = (e: React.KeyboardEvent<any>) => {
        if (e.key == 'Enter')
            onSignIn();
    }

    return (
        <React.Fragment>
            <div id="wrapper">
                <div className="login_wrapper">
                    <div className="login_outer">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="close_button text-right">
                                <span >
                                    <i className="fas fa-times" onClick={onLoginPage}></i>
                                </span>
                            </div>
                            <h3 className="text-center">Sign into <span>Abydos</span></h3>

                            <form>
                                <div className="form-group form_input">
                                    <i className="fas fa-user"></i>
                                    <input type="text" className="form-control" placeholder="Email*" value={email}
                                        onKeyDown={(event) => handleKeyEnter(event)}
                                        onChange={(event) => {
                                            setEmail(event.target.value ? event.target.value.trim() : '')
                                        }} onKeyUp={onEmailValidation} />
                                    <InputValidations isError={isEmailValid} errMessage={emailErrMsg} />
                                </div>

                                <div className="form-group form_input">
                                    <i className="fas fa-lock"></i>
                                    <div className="show_pw text-right">
                                        {passwordtype == 'text' ? <i className="far fa-eye" onClick={() => chnagePasspwardType('password')}></i> : <i className="far fa-eye-slash" onClick={() => chnagePasspwardType('text')}></i>}
                                    </div>
                                    <input type={passwordtype} className="form-control" id="signInInputPassword" placeholder="Password*" value={password}
                                        onKeyDown={(event) => handleKeyEnter(event)}
                                        onChange={(event) => {
                                            setPassword(event.target.value ? event.target.value.trim() : '');
                                        }} onKeyUp={onPasswordValidation} />
                                    <InputValidations isError={isPasswordValid} errMessage={passwordErrMsg} />
                                </div>

                                <button type="button" className="btn btn_login" disabled={disabled} onClick={onSignIn}><span>Login</span></button>

                                <small className="form-text text-muted" ><span onClick={onForgotPasswordPage}>Forgot my password?</span></small>

                                <button type="button" className="btn btn_signin" onClick={onSignUpPage}>Sign up</button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}

export default SignIn;