import React, { useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import SignIn from './authUser/SignIn';

const Login: React.FC<any> = (props: any) => {
    useEffect(() => {
        console.log(props, 'router props in login ');
        console.log('auth check in else', reactLocalStorage.getObject('authState'));
            (reactLocalStorage.getObject('authState') && reactLocalStorage.getObject('authState').isLoggedIn == true) ?
            props.history.push("/company-search") : props.history.push("/login");

    }, [

    ])
   
    return (
        <React.Fragment>
            <SignIn setCurrentState={props.setCurrentState} {...props} />
        </React.Fragment >
    )

}
export default Login;