import React from 'react';

export const SearchTips: React.FC<{}> = () => {
    return (
        <React.Fragment>
            <div className="search_right">

                <div className="search_tip">
                    <h4>Search Tips</h4>
                </div>

                <ul>


                   <li>
                        <i className="fas fa-check-circle"></i>
                        <p><span>You can search by Multiple Criteria</span>
                        Search by company name, website, or both</p>
                    </li>

                    <li>
                        <i className="fas fa-check-circle"></i>
                        <p><span>You can search by Multiple Criteria</span>
                        Drag and drop Microsoft Excel or CSV for batch portfolio processing</p>
                    </li>

                    <li>
                        <i className="fas fa-check-circle"></i>
                        <p><span>Add custom Keywords by Add Plus icon</span>
                        Search by multiple exclusions</p>
                    </li>



                </ul>
            </div>
        </React.Fragment>
    )
}
