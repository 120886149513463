import React from 'react';
import { SectorInsightDesc } from '../../../shared/constant';
import { PublishedDataInterface, AISectorDetection } from '../../../shared/interface';
import { LoadingComponenent as Loader } from '../../../shared/loading';

interface SectorInsightsProps {
    dataAISectorDetection: AISectorDetection[];
    sectorPublishedData: PublishedDataInterface[] | null;
    loadingSectors: boolean;
}
export const SectorInsights: React.FC<SectorInsightsProps> = (data: SectorInsightsProps) => {


    return (
        <React.Fragment>
            <div id="section-3" className="fund_exclusions_outer">
                <div className="actual_exclusions_side_title">
                    <div className="row">
                        <div className="col-md-12">
                            <h1><i className="fas fa-arrow-right"></i>Sector Insights</h1>
                        </div>
                    </div>
                </div>

                <div className="enhanced_sector_detectionr_outer  box_content_outer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="enhanced_sector_inner">
                                <p>{SectorInsightDesc}</p>
                            </div>
                        </div>

                        <div className="col-md-3 publish_code_outer">
                            <p className="enhanced_top_content">PUBLISHED CODE</p>
                            {data.sectorPublishedData ? data.sectorPublishedData.map((publishData, i) => {
                                return <p key={i} className="enhanced_site_num">{publishData.class.split(' ')[0] + ' '}<span>{'#' + publishData.class.split(' ')[1]}</span> </p>
                            }) : null}
                        </div>
                        <div className="col-md-9 reason_code_outer">
                            <p className="enhanced_top_content">REASON FOR EXCLUSION</p>
                            {data.sectorPublishedData ? data.sectorPublishedData.map((publishData, i) => {
                                return <p key={i} className="enhanced_site_content">{publishData.description}</p>
                            }) : null}
                        </div>
                        <div className='col-sm-12'>
                            <h4 className='small-heading'>AI Sector Detection</h4>
                            <table className="table tbl-sector-detection">
                                <tbody>
                                    <Loader loading={data.loadingSectors} />
                                    {
                                        data.dataAISectorDetection.length > 0 ?
                                            data.dataAISectorDetection.map((sector, index) => {
                                                return <tr key={index}>
                                                    <td style={{ width: '180px' }}>
                                                        <span className="mr-2"><i className="fas fa-play-circle"></i></span>
                                                        {sector.name}
                                                    </td>
                                                    <td >{sector.type}</td>
                                                </tr>

                                            }) : null}
                                </tbody>


                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}