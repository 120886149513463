import React from 'react';
import { FundExclusionsDesc } from '../../../shared/constant';
import { ActualExclusionInterface } from '../../../shared/interface';

interface ActualExclusionDataProps {
    ActualExclusionData: ActualExclusionInterface[] | null;
}

export const FundExclusions: React.FC<ActualExclusionDataProps> = (data: ActualExclusionDataProps) => {
    return (
        <React.Fragment>
            <div id="section-2" className="fund_exclusions_outer">
                <div className="actual_exclusions_side_title">
                    <div className="row">
                        <div className="col-md-12">
                            <h1><i className="fas fa-arrow-right"></i>Fund Exclusions</h1>
                        </div>
                    </div>
                </div>

                <div className="company_profile_table_outer  box_content_outer">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="enhanced_sector_inner">
                                <p>{FundExclusionsDesc}</p>
                            </div>
                        </div>

                        <div className="tournament-table-tabs users-table-tabs">
                            <h1>Potential Exclusions</h1>

                            <div id="users-player-tab">
                                <div className="table-responsive table-user">
                                    <table className="table">

                                        <tbody>
                                            <tr>
                                                <th className="tr_head">Engagement in Nuclear Weapons</th>

                                            </tr>

                                            <tr>
                                                <th>Engagement in Nuclear Weapons</th>

                                            </tr>

                                            <tr>
                                                <th>Engagement in Nuclear Weapons</th>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <h1>Actual Exclusions</h1>
                            <div id="users-orgenizer-tab">
                                <div className="table-responsive table-user table-orgenisor">
                                    <table className="table">
                                        <thead>
                                            {/* TO DO: column name not fixed */}
                                            <tr>
                                                <td>Fund Name</td>
                                                <td>REASON FOR EXCLUSION</td>
                                                <td>Date of exclusion</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.ActualExclusionData ?
                                                data.ActualExclusionData.map((data, i) => {
                                                    return <tr key={i}>
                                                        <td>{data.fund_name}</td>
                                                        <td>{data.reason}</td>
                                                        <td>{data.period}</td>
                                                    </tr>
                                                })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>




                    <div className="text-right">
                        <button className="key_matrics_btn" type="button"><span ><i className="fas fa-lock"></i>View All Exclusions</span></button>
                    </div>
                </div>
            </div>``
        </React.Fragment>
    )
}